'use strict';

angular.module('app').factory('ListHelper', function () {
	var gender = [{
		id: 'male',
		value: 'Masculino'
	}, {
		id: 'female',
		value: 'Feminino'
	}];

	var maritalStatus = [{
		id: 'single',
		value: 'Solteiro'
	}, {
		id: 'married',
		value: 'Casado'
	}, {
		id: 'common-law-marriage',
		value: 'União de facto'
	}, {
		id: 'divorced',
		value: 'Divorciado'
	}, {
		id: 'widowed',
		value: 'Viúvo'
	}];

	var jobStatus = [{
		id: '1',
		value: 'Trabalhador por conta de outrém'
	}, {
		id: '2',
		value: 'Trabalhador por conta própria ou empregador'
	}, {
		id: '3',
		value: 'Familiar não remunerado'
	}, {
		id: '4',
		value: 'Estagiário'
	}, {
		id: '5',
		value: 'Praticante / Aprendiz'
	}, {
		id: '6',
		value: 'Outra situação'
	}];

	var salaryType = [{
		id: 'monthly',
		value: 'Mensal'
	}, {
		id: 'daily',
		value: 'Diária'
	}, {
		id: 'hourly',
		value: 'Horária'
	}];

	var medicinType = [{
		id: 'internal',
		value: 'Interno'
	}, {
		id: 'external',
		value: 'Externo'
	}, {
		id: 'common',
		value: 'Comum'
	}, {
		id: 'other',
		value: 'Outro'
	}];

	var staffRole = [{
		id: 'operational',
		value: 'Operacional'
	}, {
		id: 'financial',
		value: 'Financeiro'
	}, {
		id: 'hst',
		value: 'HST'
	}, {
		id: 'external',
		value: 'Externo'
	}];

	var staffSubRole = [{
		id: 'sup',
		value: 'Técnico Superior de SHT'
	}, {
		id: 'inf',
		value: 'Técnico de SHT'
	}];

	var doctorRole = [{
		id: 'doctor',
		value: 'Médico'
	}, {
		id: 'nurse',
		value: 'Enfermeiro'
	}];

	var households = [{
		id: '1',
		value: '1'
	}, {
		id: '2',
		value: '2'
	}, {
		id: '3',
		value: '3'
	}, {
		id: '4',
		value: '4'
	}, {
		id: '5',
		value: '5'
	}, {
		id: 'other',
		value: '>5'
	}];

	var educations = [{
		id: 'preschool',
		value: '1º Ciclo'
	}, {
		id: 'primary',
		value: '2º Ciclo'
	}, {
		id: 'secondary',
		value: 'Secundário'
	}, {
		id: 'graduate',
		value: 'Superior'
	}];

	var doctorSpeciality = [
		{
			id: 1,
			value: "Clinica Geral"
		},
		{
			id: 2,
			value: "Anatomia Patológica"
		},
		{
			id: 3,
			value: "Anestesiologia"
		},
		{
			id: 4,
			value: "Angiologia e Cirurgia Vascular"
		},
		{
			id: 5,
			value: "Cardiologia"
		},
		{
			id: 6,
			value: "Cardiologia Pediátrica"
		},
		{
			id: 7,
			value: "Cirurgia Cardiotorácica"
		},
		{
			id: 8,
			value: "Cirurgia Geral"
		},
		{
			id: 9,
			value: "Cirurgia Maxilo-Facial"
		},
		{
			id: 10,
			value: "Cirurgia Pediátrica"
		},
		{
			id: 11,
			value: "Cirurgia Plástica Reco. e Est."
		},
		{
			id: 12,
			value: "Dermato-Venereologia"
		},
		{
			id: 13,
			value: "Doenças Infecciosas"
		},
		{
			id: 14,
			value: "Endocrinologia e Nutrição"
		},
		{
			id: 15,
			value: "Estomatologia"
		},
		{
			id: 16,
			value: "Gastrenterologia"
		},
		{
			id: 17,
			value: "Genética Médica"
		},
		{
			id: 18,
			value: "Ginecologia/Obstetrícia"
		},
		{
			id: 19,
			value: "Imunoalergologia"
		},
		{
			id: 20,
			value: "Imunohemoterapia"
		},
		{
			id: 21,
			value: "Farmacologia Clínica"
		},
		{
			id: 22,
			value: "Hematologia Clínica"
		},
		{
			id: 23,
			value: "Medicina Desportiva"
		},
		{
			id: 24,
			value: "Medicina do Trabalho"
		},
		{
			id: 25,
			value: "Medicina Física e de Reabilitação"
		},
		{
			id: 26,
			value: "Medicina Geral e Familiar"
		},
		{
			id: 27,
			value: "Medicina Intensiva"
		},
		{
			id: 28,
			value: "Medicina Interna"
		},
		{
			id: 29,
			value: "Medicina Legal"
		},
		{
			id: 30,
			value: "Medicina Nuclear"
		},
		{
			id: 31,
			value: "Medicina Tropical"
		},
		{
			id: 32,
			value: "Nefrologia"
		},
		{
			id: 33,
			value: "Neurocirurgia"
		},
		{
			id: 34,
			value: "Neurologia"
		},
		{
			id: 35,
			value: "Neurorradiologia"
		},
		{
			id: 36,
			value: "Oftalmologia"
		},
		{
			id: 37,
			value: "Oncologia Médica"
		},
		{
			id: 38,
			value: "Ortopedia"
		},
		{
			id: 39,
			value: "Otorrinolaringologia"
		},
		{
			id: 40,
			value: "Patologia Clínica"
		},
		{
			id: 41,
			value: "Pediatria"
		},
		{
			id: 42,
			value: "Pneumologia"
		},
		{
			id: 43,
			value: "Psiquiatria"
		},
		{
			id: 44,
			value: "Psiquiatria da Infância e da Adolescência"
		},
		{
			id: 45,
			value: "Radiologia"
		},
		{
			id: 46,
			value: "Radioncologia"
		},
		{
			id: 47,
			value: "Reumatologia"
		},
		{
			id: 48,
			value: "Saúde Pública"
		},
		{
			id: 49,
			value: "Urologia"
		}
	]

	var typesOfFiles = [
		{
			id: 'tests',
			value: 'Análises'
		}
	];

	return {
		lists: {
			gender: gender,
			maritalStatus: maritalStatus,
			jobStatus: jobStatus,
			salaryType: salaryType,
			medicinType: medicinType,
			staffRole: staffRole,
			staffSubRole: staffSubRole,
			doctorRole: doctorRole,
			doctorSpeciality: doctorSpeciality,
			typesOfFiles: typesOfFiles,
			households: households,
			educations: educations
		},

		toSelection: function (list, data, callback) {
			if (!data) {
				return callback();
			}

			callback(_.find(list, { id: data }));
		},

		toModel: function (data, property, callback) {
			if (callback === undefined && typeof property === 'function') {
				callback = property;
				property = 'id';
			}

			callback(_.get(data, property));
		}
	};
});
