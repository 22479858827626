'use strict';

angular.module('app').factory('digitalSignaturesService',
	function(Appointment) {
		function storeSignature(signature, appointment, appointmentField) {
			return new Promise(function(resolve, reject) {
				Appointment.generateSignatureImage({ digitalSignatureHash: signature }).$promise.then(function(filePath) {
					let appointmentFieldUpdate = {};
					appointmentFieldUpdate[appointmentField] = filePath.filePath;

					Appointment.prototype$updateAttributes({ id: appointment.id }, appointmentFieldUpdate).$promise.then(function(result) {
						resolve(result);
					}).catch(function(error) {
						reject(error);
					});
				}).catch(function(error) {
					reject(error);
				});
			});
		}

		return {
			afterDigitalSignatureDoctor: function(signature, appointment) {
				return storeSignature(signature, appointment, 'doctorSignature');
			},

			afterDigitalSignaturePatient: function(signature, appointment) {
				return storeSignature(signature, appointment, 'patientSignature');
			}
		};
	});
