'use strict';

angular.module('app').factory('crypto', function($q) {

	var ab2str = function(buf) {
		return String.fromCharCode.apply(null, new Uint16Array(buf));
	}

	var str2ab = function str2ab(str) {
		var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
		var bufView = new Uint16Array(buf);
		for (var i = 0, strLen = str.length; i < strLen; i++) {
			bufView[i] = str.charCodeAt(i);
		}
		return buf;
	}

	function arrayBufferToString(exportedPrivateKey) {
		var byteArray = new Uint8Array(exportedPrivateKey);
		var byteString = '';
		for (var i = 0; i < byteArray.byteLength; i++) {
			byteString += String.fromCodePoint(byteArray[i]);
		}
		return byteString;
	}

	var generateKey = function() {
		return window.crypto.subtle.generateKey(
			{
				name: "AES-CBC",
				length: 256, //can be  128, 192, or 256
			},
			true, //whether the key is extractable (i.e. can be used in exportKey)
			["encrypt", "decrypt"] //can be "encrypt", "decrypt", "wrapKey", or "unwrapKey"
		)
	}

	var encrypt = function(data, key, iv) {
		return window.crypto.subtle.encrypt(
			{
				name: "AES-CBC",
				//Don't re-use initialization vectors!
				//Always generate a new iv every time your encrypt!
				iv: iv,
			},
			key, //from generateKey or importKey above
			str2ab(data)//ArrayBuffer of data you want to encrypt
		)
	}

	var decrypt = function(data, key, iv) {
		return window.crypto.subtle.decrypt(
			{
				name: "AES-CBC",
				iv: iv, //The initialization vector you used to encrypt
			},
			key, //from generateKey or importKey above
			data //ArrayBuffer of the data
		)
	}

	var digest = function(key) {
		return window.crypto.subtle.digest('SHA-256', key);
	}

	var importKey = (key) => window.crypto.subtle.importKey(
		"raw",
		key,
		{ name: "AES-CBC" },
		true,
		["encrypt", "decrypt"]
	)

	var test = function(data) {
		console.log("String", data)
		var keyAll = null;
		var iv = window.crypto.getRandomValues(new Uint8Array(16));
		generateKey().then(function(key) {
			keyAll = key;
			return encrypt(data, keyAll, iv)
		}).then(function(data) {
			console.log("data", data)
			return decrypt(data, keyAll, iv)
		}).then(function(result) {
			console.log("result", ab2str(result));
		}).catch(function(error) {
			console.log("error", error);
		})
	}

	var encryptText = function(text, key, iv, type) {
		return new Promise(function(resolve, reject) {
			var newIv = Unibabel.base64ToBuffer(iv)
			digest(Unibabel.base64ToBuffer(key)).then(keyHash => {
				return importKey(keyHash)
			}).then(newKey => {
				if (type === 'object') text = JSON.stringify(text)
				return encrypt(Unibabel.utf8ToBase64(text), newKey, newIv)
			}).then(res => {
				resolve(Unibabel.arrToBase64(new Uint8Array(res)))
			}).catch(err => reject(err))
		})
	}

	var decryptText = function(text, key, iv, type) {
		return new Promise(function(resolve, reject) {
			var newIv = Unibabel.base64ToBuffer(iv)
			digest(Unibabel.base64ToBuffer(key)).then(keyHash => {
				return importKey(keyHash)
			}).then(newKey => {
				return decrypt(Unibabel.base64ToBuffer(text), newKey, newIv)
			}).then(dec => {
				var result = Unibabel.base64ToUtf8(Unibabel.utf8ArrToStr(new Uint16Array(dec)))
				if (type === 'object') result = JSON.parse(result)
				resolve(result)
			}).catch(err => { console.log("catch", err); reject(err) })
		})
	}

	var decryptTexts = function(texts, key, iv) {
		var promises = []
		for (var i = 0; i < texts.length; i++) {
			promises.push(decryptText(texts[i], key, iv))
		}
		return Promise.all(promises)
	}

	var encryptTexts = function(texts, key, iv) {
		var promises = []
		for (var i = 0; i < texts.length; i++) {
			promises.push(encryptText(texts[i], key, iv))
		}
		return Promise.all(promises)
	}

	var decryptObject = function(obj, objKeys, key, iv, options) {
		return new Promise(function(resolve, reject) {
			var promises = []
			var actualKeys = []
			for (var i = 0; i < objKeys.length; i++) {
				if (obj[objKeys[i]]) {
					var type = options && options.objTypes && options.objTypes[objKeys[i]]
					actualKeys.push(objKeys[i])
					promises.push(decryptText(obj[objKeys[i]], key, iv, type))
				}
			}
			Promise.all(promises).then(response => {
				for (var i = 0; i < response.length; i++) {
					obj[actualKeys[i]] = response[i]
				}
				resolve(obj)
			}).catch(err => reject(err))
		})
	}

	var encryptObject = function(obj, objKeys, key, iv, options) {
		return new Promise(function(resolve, reject) {
			var promises = []
			var actualKeys = []
			for (var i = 0; i < objKeys.length; i++) {
				if (obj[objKeys[i]]) {
					var type = options && options.objTypes && options.objTypes[objKeys[i]]
					actualKeys.push(objKeys[i])
					promises.push(encryptText(obj[objKeys[i]], key, iv, type))
				}

			}
			Promise.all(promises).then(response => {
				for (var i = 0; i < response.length; i++) {
					obj[actualKeys[i]] = response[i]
				}
				resolve(obj)
			}).catch(err => reject(err))
		})
	}

	var cryptoAction = function(action, type, obj, key, iv) {
		var objKeys = []
		var objTypes = {}
		switch (type) {
			case 'antecedent':
				objKeys = ['value', 'code', 'note', 'dosage', 'startedYear', 'endedYear', 'type', 'otherInfo']
				objTypes = { otherInfo: 'object' }
				break;
			case 'clinicDiary':
				objKeys = ['anamnese', 'objectiveExam', 'evaluation', 'plan']
				break;
			case 'nurseNote':
				objKeys = ['text']
				break;
			case 'test':
				objKeys = ['type', 'name', 'value', 'min', 'max', 'info', 'warning']
				objTypes = { info: 'object' }
				break;
		}
		if (action === "encrypt")
			return encryptObject(obj, objKeys, key, iv, { objTypes: objTypes })
		if (action === "decrypt")
			return decryptObject(obj, objKeys, key, iv, { objTypes: objTypes })
	}

	var encryptType = function(type, obj, key) {
		var iv = Unibabel.bufferToBase64(window.crypto.getRandomValues(new Uint8Array(16)));
		obj.iv = iv;
		return cryptoAction("encrypt", type, obj, key, iv)
	}

	var decryptType = function(type, obj, key) {
		var iv = obj.iv
		return cryptoAction("decrypt", type, obj, key, iv)
	}

	var encryptTypeArray = function(type, array, key) {
		var promises = []
		for (var i = 0; i < array.length; i++) {
			var iv = Unibabel.bufferToBase64(window.crypto.getRandomValues(new Uint8Array(16)));
			array[i].iv = iv
			promises.push(encryptType(type, array[i], key, iv))
		}

		return Promise.all(promises)
	}

	var decryptTypeArray = function(type, array, key) {
		var promises = []
		for (var i = 0; i < array.length; i++) {
			var iv = array[i].iv
			promises.push(decryptType(type, array[i], key, iv))
		}

		return Promise.all(promises)
	}

	var generateKeyBase64 = function() {
		return new Promise(function(resolve, reject) {
			generateKey().then(function(key) {
				return window.crypto.subtle.exportKey('raw', key)
			}).then(raw => {
				var key = Unibabel.utf8ToBase64(arrayBufferToString(raw))
				resolve(key)
			}).catch(e => {
				reject(e)
			})
		})
	}

	var saltBuffer = Unibabel.utf8ToBuffer('78953e7f115cdc9d7bb9dde85c9d41fd');

	var deriveKey = function(pass) {
		return new Promise(function(resolve, reject) {
			window.crypto.subtle.importKey(
				'raw',
				Unibabel.utf8ToBuffer(pass),
				{ name: 'PBKDF2' },
				false,
				['deriveBits', 'deriveKey'])
				.then(function(key) {
					return window.crypto.subtle.deriveKey(
						{
							"name": 'PBKDF2',
							"salt": saltBuffer,
							"iterations": 500,
							"hash": 'SHA-256'
						},
						key,
						{ "name": 'AES-CBC', "length": 256 },
						false,
						["encrypt", "decrypt"]
					)
				}).then(function(webKey) {
					resolve(webKey);
				})
				.catch(err => reject(err))
		})
	}

	var encryptTextWithText = function(text, pass) {
		return new Promise(function(resolve, reject) {
			var iv = window.crypto.getRandomValues(new Uint8Array(16));
			deriveKey(Unibabel.utf8ToBase64(pass))
				.then(newKey => {
					return encrypt(Unibabel.utf8ToBase64(text), newKey, iv)
				}).then(key => {
					resolve({ key: Unibabel.arrToBase64(new Uint8Array(key)), iv: Unibabel.bufferToBase64(iv) })
				}).catch(err => reject(err))
		})
	}

	var decryptTextWithText = function(text, pass, iv) {
		return new Promise(function(resolve, reject) {
			deriveKey(Unibabel.utf8ToBase64(pass))
				.then(newKey => {
					return decrypt(Unibabel.base64ToBuffer(text), newKey, Unibabel.base64ToBuffer(iv))
				}).then(key => {
					resolve(Unibabel.base64ToUtf8(Unibabel.utf8ArrToStr(new Uint16Array(key))))
				}).catch(err => reject(err))
		})
	}

	return {
		encrypt: encrypt,
		decrypt: decrypt,
		test: test,
		generateKey: generateKey,
		ab2str: ab2str,
		str2ab: str2ab,
		digest: digest,
		importKey: importKey,
		encryptText: encryptText,
		decryptText: decryptText,
		decryptTexts, decryptTexts,
		encryptTexts: encryptTexts,
		decryptObject: decryptObject,
		encryptObject: encryptObject,
		encryptType: encryptType,
		decryptType: decryptType,
		encryptTypeArray: encryptTypeArray,
		decryptTypeArray: decryptTypeArray,
		generateKeyBase64: generateKeyBase64,
		deriveKey: deriveKey,
		encryptTextWithText: encryptTextWithText,
		decryptTextWithText: decryptTextWithText
	};
});