'use strict';

angular.module('app').component('staff', {
	templateUrl: 'app/components/staff/staff.html',
	bindings: {
		staff: '<',
		total: '<',
		params: '<',
		limit: '<',
		companies: '<',
		asComponent: '<'
	},
	controller: [
		'focus',
		'$state',
		'$rootScope',
		'$scope',
		'CompanyService',
		function(focus, $state, $rootScope, $scope, CompanyService) {
			var $ctrl = this;
			this.searchFilter = '';

			this.search = function() {
				var q = this.searchFilter || '';
				if (!this.asComponent) {
					$state.go('app.staff', { q: q, page: 1 }, { reload: 'app.staff' });
				}
			};

			this.createStaff = function() {
				if (!this.asComponent) {
					$state.go('app.staff.add', {}, {});
				} else {
					Promise.all([CompanyService.list()]).then(function(results) {
						results[1] = results[1] ? results[1] : {};
						$rootScope.$emit('staffListAsComponentEvent', results);
					});
				}
			}

			this.$onInit = function() {
				this.searchFilter = this.params ? this.params.q : '';
				focus('search-filter');
			};
		}
	]
});
