'use strict';

// http://stackoverflow.com/a/25597540
angular.module('app')
	.factory('focus', function($timeout, $window) {
		return function(id) {
			// timeout makes sure that it is invoked after any other event has been triggered.
			// e.g. click events that need to run before the focus or
			// inputs elements that are in a disabled state but are enabled when those events
			// are triggered.
			// $timeout(function() {
			//	var element = $window.document.getElementById(id);
			//	if (element) {
			//		element.focus();
			//	}
			// });
		};
	})
	.directive('eventFocus', function(focus) {
		return function(scope, elem, attr) {
			elem.on(attr.eventFocus, function() {
				focus(attr.eventFocusId);
			});

			// Removes bound events in the element itself
			// when the scope is destroyed
			scope.$on('$destroy', function () {
				elem.off(attr.eventFocus);
			});
		};
	});
