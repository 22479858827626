'use strict';

angular.module('app').component('stats', {
	templateUrl: 'app/components/stats/stats.html',
	controller: [
		'Auth',
		'Patient',
		'Company',
		'Appointment',
		'Antecedent',
		'Test',
		'FitCert',
		'StaticLists',
		'$scope',
		'$log',
		'crypto',
		'Statistic',
		'Establishment',
		'Doctor',
		function(Auth, Patient, Company, Appointment, Antecedent, Test, Fitcert, $lists, $scope, $log, $crypto, Statistic, Establishment, Doctor) {
			var $ctrl = this;
			var user = Auth.getCachedCurrent();
			this.role = user.role;

			this.data = {};
			this.filters = {};
			this.companies = [];
			this.establishments = [];
			this.occupations = [];

			this.print = function() {
				document.getElementById("toPrint").hidden = false;

				document.getElementById("app").style.display = "none";

				document.getElementById("toPrintPrepend").appendChild(document.getElementById("pdf"));
				window.print();
				document.getElementById("toPrintAppend").appendChild(document.getElementById("pdf"));
				document.getElementById("toPrint").hidden = true;
				document.getElementById("app").style.display = "flex";
			}

			this.genders = [{
				id: 'male',
				name: 'Masculino'
			}, {
				id: 'female',
				name: 'Feminino'
			}];

			this.ages = [{
				id: '<30',
				name: '<30'
			}, {
				id: '30-50',
				name: '30-50'
			}, {
				id: '51-60',
				name: '51-60'
			}, {
				id: '>60',
				name: '>60'
			}];

			this.households = [{
				id: '1',
				name: '1'
			}, {
				id: '2',
				name: '2'
			}, {
				id: '3',
				name: '3'
			}, {
				id: '4',
				name: '4'
			}, {
				id: '5',
				name: '5'
			}, {
				id: 'other',
				name: '>5'
			}];

			this.educations = [{
				id: 'preschool',
				name: '1º Ciclo'
			}, {
				id: 'primary',
				name: '2º Ciclo'
			}, {
				id: 'secondary',
				name: 'Secundário'
			}, {
				id: 'graduate',
				name: 'Superior'
			}];

			// Default chart options
			var chartOptions = {
				responsive: true,
				maintainAspectRatio: false,
				layout: {
					padding: 0
				},
				legend: {
					display: true,
					position: 'bottom',
					fullWidth: true,
					labels: {
						boxWidth: 15
					}
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem, data) {
							var dataset = data.datasets[tooltipItem.datasetIndex];
							var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
								return previousValue + currentValue;
							});

							var label = data.labels[tooltipItem.index];
							var currentValue = dataset.data[tooltipItem.index];

							var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
							return ' ' + label + ': ' + currentValue + ' (' + percentage + '%)';
						}
					}
				}
			};

			// Load the companies from server
			this.loadCompanies = function() {
				var filter = {
					filter: {
						include: 'establishments'
					}
				};

				this.companies.length = 0;
				var user = Auth.getCachedCurrent();
				if (user.role === "doctor" || user.role === "nurse") {
					return Doctor.getAllCompanies({ id: user.doctor.id }).$promise.then(function(companies) {
						angular.copy(companies, $ctrl.companies);

						if ($ctrl.companies.length > 0) {
							var company = $ctrl.filters.company = $ctrl.companies[0];
							if (company.establishments.length > 0) {
								$ctrl.filters.establishment = company.establishments[0];
							}
						}
					});
				}
				Establishment.managesCompanies({ id: user.staff.establishment.id, filter: { include: 'establishments' } }).$promise.then(function(result) {
					angular.copy(result, $ctrl.companies);

					if ($ctrl.companies.length > 0) {
						var company = $ctrl.filters.company = $ctrl.companies[0];
						if (company.establishments.length > 0) {
							$ctrl.filters.establishment = company.establishments[0];
						}
					}
				})
					.catch(function(err) {
						$log.error('Error on loading the companies.', err);
					});
			};

			// Load the establishments to the select items
			this.loadEstablishments = function() {
				if (this.filters.company) {
					angular.copy(this.filters.company.establishments, this.establishments);
					this.filters.establishment = this.filters.company.establishments[0];
				} else {
					this.establishments.length = 0;
					delete this.filters.establishment;
				}
			};

			// Select an item from the filters
			function selectFilter(property, list, index) {
				if (index >= 0 && index < list.length) {
					$ctrl.filters[property] = list[index];
					$scope.$digest();
				}
			}

			// Gets the options to select an chart
			function getChartOptions(item, property, list) {
				if (property && list) {
					return {
						data: item.data,
						labels: item.labels,
						options: _.extend({}, {
							onClick: function(e, args) {
								if (args && args.length > 0) {
									selectFilter(property, list, args[0]._index);
								}
							}
						}, chartOptions)
					};
				}

				return {
					data: item.data,
					labels: item.labels,
					options: _.merge({}, chartOptions)
				};
			}

			// Gets the current filter values
			this.getFilterValues = function() {
				var companyId = _.get(this.filters, 'company.id');
				var establishmentId = _.get(this.filters, 'establishment.id');
				if (!companyId || !establishmentId) {
					return null;
				}

				return {
					companyId: companyId,
					establishmentId: establishmentId,
					gender: _.get(this.filters, 'gender.id'),
					age: _.get(this.filters, 'age.id'),
					household: _.get(this.filters, 'household.id'),
					educationStage: _.get(this.filters, 'education.id')
				};
			};

			this.encrypt = function(data) {
				var iv = window.crypto.getRandomValues(new Uint8Array(16));
				var keyAll = null;
				return $crypto.generateKey()
					.then(function(key) {
						keyAll = key;
						return $crypto.encrypt(data, keyAll, iv)
							.then(function(result) {
								return Unibabel.bufferToBase64(new Uint8Array(result));
							});
					});
			};

			this.getQueryOptions = function() {
				return new Promise(function(resolve, reject) {
					var companyId = _.get(this.filters, 'company.id');
					var establishmentId = _.get(this.filters, 'establishment.id');
					if (!companyId || !establishmentId) {
						return null;
					}

					resolve({
						date: moment().toISOString(),
						companyId: companyId,
						establishmentId: establishmentId,
						gender: _.get(this.filters, 'gender.id'),
						age: _.get(this.filters, 'age.id'),
						household: _.get(this.filters, 'household.id'),
						educationStage: _.get(this.filters, 'education.id')
					});
				}.bind(this));
			};



			// Load the population stats
			this.loadPopulationStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getPopulationStats(options).$promise;
					})
					.then(function(result) {
						var data = {
							gender: getChartOptions(result.gender),
							age: getChartOptions(result.age),
							household: getChartOptions(result.household),
							education: getChartOptions(result.education),
							occupation: getChartOptions(result.occupation)
						};

						data.populationTotal = _.sum(result.gender.data);
						return data;
					});
			};

			this.loadMedicalStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getMedicalStats(options).$promise;
					})
					.then(function(result) {
						var data = {
							medical: getChartOptions(result.main),
							occasionalMedical: getChartOptions(result.occasional)
						};

						data.medicalTotal = _.sum(result.main.data);
						data.occasionalMedical.options.legend.position = 'right';

						return data;
					});
			};

			this.loadNursingStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getNursingStats(options).$promise;
					})
					.then(function(result) {
						var data = {
							nursingTotal: result.total,
							exams: getChartOptions(result.exams),
							vaccines: getChartOptions(result.vaccines)
						};

						return data;
					});
			};

			this.loadHypertensiveStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getHypertenseStats(options).$promise;
					})
					.then(function(result) {
						return {
							hypertensive: result
						};
					});
			};

			this.loadDiabeticStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getDiabeticStats(options).$promise;
					})
					.then(function(result) {
						return {
							diabetic: result
						};
					});
			};

			this.loadSmokerStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getSmokerStats(options).$promise;
					})
					.then(function(result) {
						return {
							smoker: result
						};
					});
			};

			this.loadOverweightStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getOverweightStats(options).$promise;
					})
					.then(function(result) {
						result.data0.male.splice(-1, 1);
						result.data1.male.shift();
						result.data0.female.splice(-1, 1);
						result.data1.female.shift();

						return {
							overweight: result
						};
					});
			};

			this.loadNoDoctorCount = function() {
				return this.getQueryOptions()
					.then(function(options) {
						return Statistic.getNoDoctorCount(options).$promise;
					})
					.then(function(result) {
						return {
							noDoctorTotal: result.count || 0
						};
					});
			};

			this.getNoDoctorPercent = function() {
				if (this.data.noDoctorTotal > 0 && this.data.populationTotal > 0) {
					return Math.round(this.data.noDoctorTotal * 100.0 / this.data.populationTotal);
				}

				return 0;
			};

			this.loadVaccineStats = function() {
				return this.getQueryOptions()
					.then(function(options) {
						// TODO: Devem passar um array com as vacinas que serão consultadas,
						// bem como a periodicidade da mesma.
						// Estas vacinas estão presentes na base de dados: Vaccine.find()
						options.vaccines = [{
							name: 'vaccine-10',
							period: 365
						}, {
							name: 'vaccine-11',
							period: 3650
						}, {
							name: 'vaccine-12',
							period: 3650
						}];

						return Statistic.getVaccineStats(options).$promise
							.then(function(result) {
								return { vaccination: result };
							});
					});
			};


			// Load the appointment stats
			this.loadAppointmentsStats = function() {
				var payload = this.getFilterValues();
				payload.startDate = moment().startOf("year").toISOString();
				payload.endDate = moment().endOf("day").toISOString();
				payload.period = 'monthly';

				return Appointment.getStats(payload).$promise
					.then(function(result) {
						result.exam.labels = $lists.translateArray(
							result.exam.labels,
							$lists.appointmentTypes
						);

						return {
							appointments: {
								data: [result.time.data],
								labels: result.time.labels,
								series: ['Consultas por mês'],
								options: {
									responsive: true,
									maintainAspectRatio: false,
									layout: {
										padding: 0
									},
									legend: {
										display: false
									}
								}
							},
							appointmentsType: getChartOptions(result.exam)
						};
					});
			};

			// Load the prevalence illnesses stats
			this.loadPrevalenceIllnessesStats = function() {
				var payload = this.getFilterValues();

				return Antecedent.getStats(payload).$promise
					.then(function(result) {
						return {
							prevalenceByPatient: getChartOptions(result.ratioByPatient),
							prevalenceByIllness: getChartOptions(result.ratioByIllness)
						};
					});
			};

			// Load the incidence illnesses stats
			this.loadIncidenceIllnessesStats = function() {
				var payload = this.getFilterValues();
				payload.year = 2017;

				return Antecedent.getStats(payload).$promise
					.then(function(result) {
						return {
							incidenceByPatient: getChartOptions(result.ratioByPatient),
							incidenceByIllness: getChartOptions(result.ratioByIllness)
						};
					});
			};

			this.loadTestStats = function() {
				var payload = this.getFilterValues();
				payload.year = 2017;

				return Test.getStats(payload).$promise
					.then(function(result) {
						result.bmi.labels = $lists.translateArray(
							result.bmi.labels,
							$lists.bmiScales
						);

						return {
							pressure: {
								data: result.pressure.data,
								labels: result.pressure.labels,
								series: ['Sistólica', 'Diastólica'],
								options: {
									responsive: true,
									maintainAspectRatio: false,
									layout: {
										padding: 0
									},
									legend: {
										display: true
									}
								}
							},
							bmi: getChartOptions(result.bmi)
						};
					});
			};

			this.loadFitnessStats = function() {
				var payload = this.getFilterValues();
				payload.year = 2017;

				return Fitcert.getStats(payload).$promise
					.then(function(result) {
						result.fitness.labels = $lists.translateArray(
							result.fitness.labels,
							$lists.fitnessResults
						);

						return {
							fitness: getChartOptions(result.fitness)
						};
					});
			};

			// Load all stats from server
			this.loadStats = function() {
				return Promise.all([
					this.loadPopulationStats(),
					this.loadMedicalStats(),
					this.loadNursingStats(),
					this.loadHypertensiveStats(),
					this.loadDiabeticStats(),
					this.loadSmokerStats(),
					this.loadOverweightStats(),
					this.loadNoDoctorCount(),
					this.loadVaccineStats(),


					this.loadAppointmentsStats(),
					this.loadPrevalenceIllnessesStats(),
					this.loadIncidenceIllnessesStats(),
					this.loadTestStats(),
					this.loadFitnessStats()
				]).then(function(result) {
					_.extend($ctrl.data, _.assign.apply(_, result));
					$scope.$digest();
				}).catch(function(err) {
					$log.error('Error on loading the statistics', err);
				});
			};

			this.loadCompanies();
		}
	]
});
