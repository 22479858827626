'use strict';

angular.module('app').factory('PatientDeadlineService', ['Patient', 'moment', 'PatientDeadline', function(Patient, moment, PatientDeadline) {
	function createDeadLine(patient, appointment) {
		return Patient.upsertDeadline({ patientId: patient.id, appointmentDate: appointment.dateTime }, {}).$promise;
	}

	return {
		createDeadLine: createDeadLine
	};
}]);
