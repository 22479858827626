angular.module('app').component('weekDayAvailability', {
	bindings: {
		availabilities: '<',
		day: '<',
		patients: '<',
		establishment: '<',
		getWeek: '&'
	},
	templateUrl: 'app/components/week-day-availability/week-day-availability.html',
	controller: function(Doctor, Auth, moment, LxDropdownService, DoctorAvailability, LxDialogService, Establishment, Appointment) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();

		$ctrl.exams = [{ name: "admission", label: "Admissão" }, { name: "periodic", label: "Periódico" }, { name: "disease", label: "Ocasional - Após doença" }, { name: "accident", label: "Ocasional - Após acidente" }, { name: "patientRequest", label: "Ocasional - A pedido do trabalhador" }, { name: "companyRequest", label: "Ocasional - A pedido do serviço" }, { name: "jobChange", label: "Ocasional - Por mudança de função" }, { name: "jobConditionsChanged", label: "Ocasional - Por alteração das condições de trabalho" }]
		$ctrl.selectedExam = $ctrl.exams[1];

		$ctrl.acceptProccesses = [{ name: "request", label: "Pedir confirmação de disponibilidade ao(s) trabalhador(es)" }, { name: "accept", label: "Confirmar de imediato todas as consultas" }]
		$ctrl.acceptProccess = $ctrl.acceptProccesses[1]

		$ctrl.$onChanges = function(changes) {
			if (changes.availabilities && changes.availabilities.currentValue) {
				$ctrl.availabilitiesPeriod = _.groupBy($ctrl.availabilities, "period");

				var availabilitiesByPeriod = $ctrl.availabilitiesPeriod;

				_.each($ctrl.availabilitiesPeriod, function(periods, keyPeriods, listPeriods) {

					var times = _.groupBy(listPeriods[keyPeriods], function(item) {
						return moment(item.start).toISOString();
					})

					times = _.each(times, function(time, keyTime, listTime) {
						listTime[keyTime] = _.groupBy(time, "locationId")
					})

					listPeriods[keyPeriods] = {
						possibleLocations: _.groupBy(listPeriods[keyPeriods], "locationId"),
						times: times
					}
				})
                /* _.each($ctrl.availabilitiesPeriod, function(periods, keyPeriods, listPeriods) {
                     var times = {};
                     for (var i = 0; i < periods.length; i++) {
                         var time = moment(periods[i].start).clone();
                         while (!moment(time).isSame(moment(periods[i].end).clone().add(15, 'minutes'))) {

                             if (!times[time.toISOString()]) {
                                 times[time.toISOString()] = [];
                             }
                             var appointment = _.find(periods[i].appointments, function(item) {
                                 return item.dateTime === time.toISOString()
                             });
                             times[time.toISOString()].push({ availability: periods[i], appointment: appointment });
                             var x = _.groupBy(times[time.toISOString()], "locationId");
                             time = time.add(15, 'minutes');
                         };
                     }
                     _.each(times, function(time, key, list) {
                         list[key] = _.groupBy(time, 'availability.locationId');
                     })

                     listPeriods[keyPeriods] = { availabilities: periods, times: angular.copy(times), possibleLocations: _.groupBy(periods, 'locationId') }
                 });*/

			}
		}

		$ctrl.getAppointment = function(time) {
			if (!time)
				return;
			var count = 0;
			for (var i = 0; i < time.length; i++) {
				if (time[i].appointment) {
					count++;
				}
			}
			return count;
		}

		$ctrl.addAppointment = function(date, availabilities) {
			$ctrl.selectedDate = date;
			$ctrl.selectedAvailabilities = availabilities;
			$ctrl.selectedAvailability = $ctrl.selectedAvailabilities[0];
			$ctrl.removeAvailabilities = [];
			$ctrl.selectedDoctors = [];
			$ctrl.selectedPeriods = [];

			//$ctrl.selectedDoctors = angular.copy($ctrl.establishment.doctors);
			$ctrl.selectedPatients = angular.copy($ctrl.patients);

			$ctrl.generateAppointments();

			LxDialogService.open($ctrl.day.iso);

		}

		$ctrl.generateAppointments = function() {
			$ctrl.loading = true;
			Establishment.generateAppointments({ blocked: _.map($ctrl.removeAvailabilities, "id"), periods: _.map($ctrl.selectedPeriods, "id"), doctors: _.map($ctrl.selectedDoctors, "id"), id: $ctrl.establishment.id, locationAvailabilityId: $ctrl.selectedAvailability.id, patients: _.map($ctrl.selectedPatients, "id") }).$promise.then(function(result) {
				$ctrl.generatedAppointments = result.generatedAppointments;
				$ctrl.loading = false;
			}).catch(function(err) {
				$ctrl.loading = false;
			})
		}

		$ctrl.saveAppointment = function() {
			var appointments = [];
			var doctor = null;
			for (var i = 0; i < $ctrl.generatedAppointments.length; i++) {
				if ($ctrl.generatedAppointments[i].locationAvailability.doctor) {
					doctor = $ctrl.generatedAppointments[i].locationAvailability.doctor.id;
				} else {
					doctor = null;
				}

				appointments.push({
					dateTime: $ctrl.generatedAppointments[i].locationAvailability.start,
					patientId: $ctrl.generatedAppointments[i].patient.id,
					locationId: $ctrl.generatedAppointments[i].locationAvailability.locationId,
					locationAvailabilityId: $ctrl.generatedAppointments[i].locationAvailability.id,
					doctorId: doctor,
					exam: $ctrl.selectedExam.name || "periodic"
				})
			}

			Establishment.createAppointments({ id: $ctrl.establishment.id, appointments: appointments }).$promise.then(function(result) {
				$ctrl.getWeek();
				LxDialogService.close($ctrl.day.iso)
			});
		}

		$ctrl.removePatients = function() {
			$ctrl.selectedPatients = _.map(_.filter($ctrl.generatedAppointments, function(item) { if (!item.selected) return true }), "patient");
			$ctrl.generateAppointments();
		}

		$ctrl.removeAvailability = function() {
			$ctrl.removeAvailabilities = _.concat($ctrl.removeAvailabilities, _.map(_.filter($ctrl.generatedAppointments, function(item) { if (item.selected) return true }), "locationAvailability"));
			$ctrl.generateAppointments();
		}

		$ctrl.isSelected = function() {
			return _.some($ctrl.generatedAppointments, { selected: true });
		}

		$ctrl.periods = {
			0: { name: "morning", label: "Manhã", start: { hour: 8, minute: 0 }, end: { hour: 12, minute: 0 }, min: { hour: 7, minute: 0 }, max: { hour: 13, minute: 0 } },
			1: { name: "afternoon", label: "Tarde", start: { hour: 13, minute: 0 }, end: { hour: 20, minute: 0 }, min: { hour: 13, minute: 0 }, max: { hour: 20, minute: 0 } },
			2: { name: "night", label: "Noite", start: { hour: 20, minute: 0 }, end: { hour: 24, minute: 0 }, min: { hour: 20, minute: 0 }, max: { hour: 24, minute: 0 } }
		};

		$ctrl.periodsArray = [
			{ id: 0, name: "morning", label: "Manhã", start: { hour: 8, minute: 0 }, end: { hour: 12, minute: 0 }, min: { hour: 7, minute: 0 }, max: { hour: 13, minute: 0 } },
			{ id: 1, name: "afternoon", label: "Tarde", start: { hour: 13, minute: 0 }, end: { hour: 20, minute: 0 }, min: { hour: 13, minute: 0 }, max: { hour: 20, minute: 0 } },
			{ id: 2, name: "night", label: "Noite", start: { hour: 20, minute: 0 }, end: { hour: 24, minute: 0 }, min: { hour: 20, minute: 0 }, max: { hour: 24, minute: 0 } }
		];

	}
})