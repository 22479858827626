angular.module('app').component('staffFormMasterDetail', {
	templateUrl: 'app/components/staff/staff-form.html',
	bindings: {
		companies: '<',
		member: '<',
		asComponent: '<'
	},
	controller: [
		'Staff',
		'ListHelper',
		'FormHelper',
		'$state',
		'$log',
		'focus',
		'LxDialogService',
		'$rootScope',
		'$scope',
		'NotificationHelper',
		function (Staff, ListHelper, FormHelper, $state, $log, focus, LxDialogService, $rootScope, $scope, NotificationHelper) {
			var $ctrl = this;
			this.list = ListHelper;
			this.form = FormHelper;

			this.loading = false;
			this.saving = false;
			this.editing = false;
			this.data = {};
			this.title = null;
			this.establishments = [];
			this.companyId = null;
			this.establishmentId = null;

			this.confirmRemoveStaff = () => {
				LxDialogService.open('confirmDelete');
			};

			this.removeStaff = () => {
				this.loading = true;
				Staff.deleteById({ id: this.data.id }).$promise.then(outcome => {
					if ($ctrl.asComponent) {
						$state.go('app.accessControl', {}, { reload: 'app.accessControl' });
					} else {
						$state.go('app.staff', {}, { reload: 'app.staff' });
					}
				});
			};


			this.getData = () => {
				return _.assign({}, this.data);
			};

			this.edit = () => {
				this.editing = true;
				setTimeout(() => {
					focus('member-name');
				}, 0);
			};

			this.companySelectionChanged = () => {
				this.establishmentId = null;
				if (this.companyId) {
					var company = _.find(this.companies, { id: this.companyId });
					if (company && company.establishments) {
						angular.copy(company.establishments, this.establishments);
						$ctrl.establishmentId = company.establishments[0].id;
						return;
					}
				}

				this.establishments.length = 0;
			};

			this.submit = () => {
				this.saving = true;

				var data = this.getData();
				data.companyId = this.companyId;
				data.establishmentId = this.establishmentId;

				var promise;
				if (data.id) {
					promise = Staff.replaceById({ id: data.id }, data).$promise;
				} else {
					promise = Staff.create(data).$promise;
				}

				this.loading = true;

				return promise
					.then(result => {
						this.loading = false;
						this.saving = false;
						NotificationHelper.successMessage();
						if ($ctrl.asComponent) {
							$state.go('app.accessControl', {}, { reload: 'app.accessControl' });
						} else {
							$state.go('app.staff.detail', { memberId: result.id }, { reload: 'app.staff' });
						}
					})
					.catch(err => {
						NotificationHelper.errorMessage();
						this.saving = false;
						$log.error('error on adding the staff', err);
						if ($ctrl.asComponent) {
							$state.go('app.accessControl', {}, { reload: 'app.accessControl' });
						} else {
							$state.go('app.staff', { }, { reload: 'app.staff' });
						}
					});
			};

			this.cancel = () => {
				if (this.member && this.member.id) {
					this.copyData();
					this.editing = false;
				} else {
					$state.go('app.staff');
				}
			};

			this.copyData = () => {
				var data = this.member.toJSON();
				data.email = this.member.auth.email;
				angular.copy(data, this.data);

				this.companyId = this.data.companyId;
				$ctrl.companySelectionChanged();
				this.establishmentId = this.data.establishmentId;
			};

			this.initComponent  = () => {
				if ($ctrl.member && $ctrl.member.id) {
					$ctrl.copyData();
					$ctrl.editing = false;
					$ctrl.title = $ctrl.member.name;
				} else {
					$ctrl.title = 'Novo Membro';
					$ctrl.edit();
				}
			}

			this.establishmentChanged = (oldValue, newValue) => {
				var selectedEst;

				if (newValue) {
					selectedEst = _.find($ctrl.establishments, (item) => {
						return item.id === newValue;
					});

					if (selectedEst && selectedEst.external) {
						this.data.role = 'external';
					}
				}
			};

			$rootScope.$on('staffListAsComponentEvent', (evt, data) => {
				$ctrl.member = data[1];
				//$ctrl.companies = data[0];
				this.initComponent();
				$ctrl.isCreating = data[1] && !data[1].id ? true : false;

				if ($ctrl.asComponent) {
					$ctrl.data.role = 'external';
				}

				$scope.$apply();
			});

			this.$onInit = () => {
				this.initComponent();
			};
		}
	]
});
