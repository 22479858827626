'use strict';

angular.module('app').component('chatNewConversationItem', {
	templateUrl: 'app/components/chat/chat-new-conversation-item.html',
	bindings: {
		user: '<',
		onClick: '&'
	},
	controller: [
		function() {
			this.click = function() {
				this.onClick({ user: this.user });
			};
		}
	]
});
