'use strict';

angular.module('app').factory('NotificationHelper', [
	'LxNotificationService',
	function (LxNotificationService) {
        return {
            successMessage: () => {
                LxNotificationService.success('Operação realizada com sucesso.');
            },
            errorMessage: () => {
                LxNotificationService.error('Ocorreu um erro com a operação solicitada por favor tente novamente mais tarde.');
            }
        }
    }
]);
