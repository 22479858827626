'use strict';

angular.module('app').component('chat', {
	templateUrl: 'app/components/chat/chat.html',
	controller: [
		'$scope',
		'$rootScope',
		'$timeout',
		'$log',
		'Auth',
		'ChatService',
		function($scope, $rootScope, $timeout, $log, Auth, ChatService) {
			var audio = new Audio('/fs/audio/message.mp3');
			var $ctrl = this;
			this.initialized = false;
			this.hide = true;
			this.isNewConversation = false;

			this.recipient = null;
			this.currentUser = null;
			this.conversations = [];

			this.unreadConversations = 0;

			this.getTitle = function() {
				if (this.isNewConversation) {
					return 'Nova Conversa';
				}

				if (this.recipient) {
					return this.recipient.recipient.name;
				}

				return 'Chat';
			};

			this.selectConversation = function(conversation) {
				this.recipient = conversation;
			};

			this.unselectConversation = function() {
				this.recipient = null;
			};

			this.newConversation = function() {
				this.isNewConversation = true;
			};

			this.cancelNewConversation = function() {
				this.isNewConversation = false;
			};

			this.openChat = function(user) {
				if (this.busy) {
					return;
				}

				this.busy = true;

				ChatService.getConversation(this.currentUser.id, user.id, user.name)
					.then(angular.bind(this, function(conversation) {
						this.isNewConversation = false;
						this.busy = false;
						this.recipient = conversation;
					}));
			};

			this.getConversations = function() {
				return ChatService.getConversations()
					.then(angular.bind(this, function(conversations) {
						angular.copy(conversations, this.conversations);
						this.initialized = true;
						this.getUnreadConversation();
					}))
					.catch(function(err) {
						$log.error('error on gettting the conversations.', err);
					});
			};

			this.getUnreadConversation = function() {
				if (this.conversations && this.conversations.length) {
					this.unreadConversations = this.conversations.reduce(function(total, conversation) {
						if (conversation.lastMessage && conversation.lastMessage.mine) {
							return 0;
						} else {
							if (conversation.lastMessage.recipientId && conversation.lastMessage.recipientId === conversation.recipient.id) {
								return 0;
							} else {
								return 1;
							}
						}
					}, 0);
				} else {
					return 0;
				}
			};

			this.$onInit = function() {
				var user = Auth.getCachedCurrent();
				if (!user) {
					/*Auth.getCurrent().$promise
						.then(angular.bind(this, function(u) {
							this.currentUser = u;
							return this.getConversations();
						}))
						.catch(function(err) {
							$log.error('error on gettting the current user.', err);
						});*/

					setTimeout(function() {
						$ctrl.$onInit();
					}, 1000);

					this.getUnreadConversation();
				} else {
					this.getConversations();
					this.currentUser = user;
					this.isExternal = user.role === "external";
					this.initialized = true;
				}
			};

			$rootScope.$on('chat.message', angular.bind(this, function(evt, data) {
				var current = _.find(this.conversations, { id: data.conversationId });
				if (current) {
					$timeout(function() {
						current.lastMessage = data;
						$ctrl.getUnreadConversation();
					});
				} else {
					this.getConversations();
				}

				if (data.recipientId === this.currentUser.id) {
					audio.play();
					ChatService.updateStatus(data.id, data.ownerId, 'delivered');
				}
			}));

			$rootScope.$on('chat.reload', angular.bind(this, function() {
				this.getConversations();
			}));

			$rootScope.$on('chat.filter', angular.bind(this, function(evt, recipient) {
				var filteredChat = this.conversations.find(function(item) {
					return recipient.auth.id === item.recipient.id;
				});

				if (filteredChat) {
					this.selectConversation(filteredChat);
					this.hide = false;
				} else {
					ChatService.getConversation(this.currentUser.id, recipient.auth.id, recipient.auth.name)
						.then(angular.bind(this, function(conversation) {
							this.isNewConversation = false;
							this.busy = false;
							this.recipient = conversation;
							this.hide = false;
							
						}));
				}
			}));
		}]
});
