'use strict';

angular.module('app')
    .factory('socket', function(LoopBackAuth, $location) {
        var socket = io.connect( /*'http://localhost:3000'*/ $location.host() === "localhost" ? "localhost:3000" : $location.host());

        var id = LoopBackAuth.accessTokenId;
        var userId = LoopBackAuth.currentUserId;

        socket.on('connect', function() {
            socket.emit('authentication', { id: id, userId: userId });
            socket.on('authenticated', function() {});
        });

        return socket;
    });