'use strict';

angular.module('app').component('staffListItem', {
	templateUrl: 'app/components/staff/staff-list-item.html',
	bindings: {
		member: '<',
		asComponent: '<'
	},
	controller: [
		'$state',
		'CompanyService',
		'StaffService',
		'$rootScope',
		function($state, CompanyService, StaffService, $rootScope) {
			this.openDetail = function(member) {
				if (this.asComponent) {
					Promise.all([CompanyService.list(), StaffService.get(member.id)]).then(function(results) {
						$rootScope.$emit('staffListAsComponentEvent', results);
					});
				} else {
					$state.go('app.staff.detail', { memberId: member.id });
				}
			};
		}
	]
});
