'use strict';

angular.module('app').component('evaluationListDisplay', {
	templateUrl: 'app/components/evaluation-list-display/evaluation-list-display.html',
	bindings: {
		higSecEvaluations: '<'
	},
	controller: function(ImprovementControlService) {
		var $ctrl = this;

		$ctrl.getWords = function(str) {
			var strAux = str.slice(0, 35);
			return str.length < 34 ? strAux : strAux + '...';
		};

		$ctrl.statuses = ImprovementControlService.getStatus();

		this.$onInit = function() {
			this.higSecEvaluations.forEach(function(elem) {
				elem.inEvaluation = 0;
				elem.inImpl = 0;
				elem.closed = 0;
				elem.allSections = [];

				if (elem.riskEvaluations.length) {
					elem.intervention = elem.riskEvaluations[1] ? elem.riskEvaluations[1].intervention : elem.riskEvaluations[0].intervention;
					elem.acceptable = elem.riskEvaluations[1] ? elem.riskEvaluations[1].acceptable : elem.riskEvaluations[0].acceptable;
				}

				_.forEach(_.groupBy(elem.sections, 'workstation.id'), function(value, key) {
					elem.allSections.push(value[0].workstation.name + ' - ' + value.map(function(section) {
						return section.name;
					}).join(','));
				});

				if (elem.riskControlActions) {
					var count = elem.riskControlActions.filter(function(filterElem) {
						return filterElem.status === $ctrl.statuses[0];
					}).length;

					elem.inEvaluation = count;

					count = elem.riskControlActions.filter(function(filterElem) {
						return filterElem.status === $ctrl.statuses[1];
					}).length;

					elem.inImpl = count;

					count = elem.riskControlActions.filter(function(filterElem) {
						return filterElem.status === $ctrl.statuses[2] || filterElem.status === $ctrl.statuses[3];
					}).length;

					elem.closed = count;
				}
			});
		};

		$ctrl.getRiskClass = function(interventionLevel) {
			if (interventionLevel) {
				if (interventionLevel === 'IV')
					return 'tc-green-500';
				if (interventionLevel === 'III')
					return 'tc-yellow-700';
				if (interventionLevel === 'II')
					return 'tc-orange-500';
				if (interventionLevel === 'I')
					return 'tc-red-500';
			}
			return '';
		};
	}
});
