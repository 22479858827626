'use strict';

angular.module('app').component('patientsFilter', {
	templateUrl: 'app/components/patients-filter/patients-filter.html',
	controller: [
		'Company',
		'Staff',
		'$scope',
		'$log',
		'Establishment',
		'Auth',
		'Location',
		function(Company, Staff, $scope, $log, Establishment, Auth, Location) {
			var self = this;

			this.periocities=['Anual', 'Bianual']
			this.search = "";

			this.more = false;
			this.filters = {};
			this.companies = [];
			this.establishments = [];
			this.locations = [];
			this.staff = [];

			// Gets the companies from server
			this.loadCompanies = function() {
				
				var user = Auth.getCachedCurrent();

				Establishment.managesCompanies({ id: user.staff.establishment.id, filter: { include: {'establishments': ['locations', 'company', 'doctors', 'patients'] } } }).$promise.then(function(companies) {
					angular.copy(companies, self.companies);

					if(!self.companies || !self.companies.length) {	
						const establishment = JSON.parse(JSON.stringify(user.staff.establishment));

						delete establishment.company;

						user.staff.establishment.company.establishments = [establishment];
	
						self.companies.push(user.staff.establishment.company);
					}
				});


				/*return Company.find({filter: filter}).$promise
					.then(function(companies) {
						angular.copy(companies, self.companies);
					})
					.catch(function(err) {
						$log.error('Error on loading the companies from server.', err);
					});*/
			};


			/*this.loadStaff = function() {
				var user = Auth.getCachedCurrent();
				Establishment.staffs({ id: user.staff.establishment.id }).$promise.then(function(staff) {
					angular.copy(staff, self.staff);
				}.bind(this)).catch(function(err) {
					console.log('Error on getting the data from server.', err);
				});
				/*
				return Staff.find().$promise
					.then(function(staff) {
						angular.copy(staff, self.staff);
					})
					.catch(function(err) {
						$log.error('Error on loading the staff from server.', err);
					});*/
			//};

			// When the company was changed, update the establishment list
			this.companyChanged = function() {
				delete self.filters.establishment;

				if (this.filters.company && this.filters.company.establishments) {
					angular.copy(this.filters.company.establishments, this.establishments);
				} else {
					this.establishments.length = 0;
				}

				//console.log(this.establishments);
				this.triggerChanges();


			};


			// When the establishment was changed, update the location list
			/*this.establishmentChanged = function() {
				//delete self.filters.location;

				
				if (this.filters.company && this.filters.company.establishments && this.filters.company.establishments[0].locations) {
					angular.copy(this.filters.company.establishments[0].locations, this.locations);
				} else {
					this.locations.length = 0;
				}



				
			};*/


			//--------------------------------------------------------------- 

			this.triggerChanges = function() {
				var company = _.get(this.filters, 'company');
				var establishment = _.get(this.filters, 'establishment');
				var periocities = _.get(this.filters, 'periocities');
				var search = _.get(this.filters, 'search')
				var filters = {
					company: company,
					establishment: establishment,
					periocities: periocities,
					search: search
				};

				//console.log(establishment);

				//console.log(companyId);
				//console.log(establishment);

				this.onChanged({ filters: _.pickBy(filters, _.identity) });
				//this.deleteFilters();

			};

			//this.deleteFilters = function() {
				
				
					
			//};


			this.$onInit = function() {
				     
				Promise.all([
					this.loadCompanies()
					//this.loadStaff()
				]);
			};
		}],
	bindings: {
		onChanged: '&'
	}
});
