'use strict';

angular.module('app').component('fitCertsTable', {
	templateUrl: 'app/components/fit-certs/fit-certs-table/fit-certs-table.html',
	bindings: {
		fitCertsData: '<',
		tableType: '<',
		establishments: '<',
		tabletitle: '<',
		subtitle: '<'
	},
	controller: function(moment, StaticLists, $state, $window, $scope, LxDialogService) {
		function getFiltersEntriesByField(table, field) {
			return _.uniqBy(_.map(table, tableLine => tableLine[field]));
		}

		this.goBack = function() {
			$scope.$emit('goBack', null);
		};

		this.showNextAppointment = function(appointmentId) {
			$state.go('appointment', { id: appointmentId });
		}

		this.$onChanges = function(changes) {
			if (changes && changes.fitCertsData && this.tableType === 'custom') {
				this.initComponent();
			}
		};

		this.initComponent = function() {
			//Estabelecimento; Área; Função; Periodicidade; Nome / No mecanográfico (Filters)
			this.allResults = angular.copy(this.fitCertsData);
			this.selectedEstablishments = angular.copy(this.establishments);
			this.frequencyFilter = getFiltersEntriesByField(this.fitCertsData, 'frequency');
		}

		this.$onInit = function() {
			this.initComponent();
		};

		this.goToPatientFAM = function(line) {
			$state.go("appointmentSheet", { id: line.patientId, scheduling: true });
		}

		this.applyFilter = function() {
			this.filterApplied = true;
			this.fitCertsData = angular.copy(this.allResults);
			this.establishmentNames = this.selectedEstablishments.map(establishment => establishment.name);

			if (this.establishmentNames && this.establishmentNames.length) {
				this.fitCertsData = this.fitCertsData.filter(line => this.establishmentNames.indexOf(line.establishmentName) >= 0);
			}

			if (this.selectedFrequency) {
				this.fitCertsData = this.fitCertsData.filter(function(line) {
					return line.frequency === this.selectedFrequency;
				}.bind(this));
			}

			if (this.workerId) {
				this.fitCertsData = this.fitCertsData.filter(function(line) {
					return line.patientName.toLowerCase().indexOf(this.workerId.toLowerCase()) >= 0 || (line.workerNumber && line.workerNumber.toLowerCase().indexOf(this.workerId.toLowerCase()) >= 0)
				}.bind(this));
			}
		}

		this.showFAM = function(line) {
			$window.open('/api/attachments/reports/download/' + line.filename, '_blank');
		};

		this.showWithoutRevaluationComment = function(line) {
			this.revaluationComment = line.withoutRevaluationComment;
			LxDialogService.open(this.tableType);
		}
	}
});
