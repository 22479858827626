angular.module('app').component('schedulingBlocks', {
	bindings: { establishment: '<', patients: '<', getPatients: '&', start: '<', view: '=', location: '<' },
	templateUrl: 'app/components/scheduling-blocks/scheduling-blocks.html',
	controller: function(Doctor, Auth, Establishment, $stateParams, Location) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();
		$ctrl.user = user;

		$ctrl.$onInit = function(changes) {
			$ctrl.currentWeek = $ctrl.start ? moment($ctrl.start) : moment();
			$ctrl.getWeek();

		}

		$ctrl.getWeek = function() {
			if ($ctrl.location == null) {
				$ctrl.loading = true;
				Establishment.getLocationAvailabilities({
					id: $ctrl.establishment.id,
					filter: {
						include: ['location', 'doctor', 'appointment'],
						where: { start: { between: [$ctrl.currentWeek.clone().startOf("day"), $ctrl.currentWeek.clone().add(7, "days").startOf("day")] } }
					}
				}).$promise.then(function(result) {
					$ctrl.result = result;
					$ctrl.locationAvailabilities = _.groupBy(result, function(item) {
						item.start = moment(item.start).utc();
						item.end = moment(item.end).utc();
						return moment(item.start).utc().startOf('day').format("YYYY-MMM-DD");
					});
					$ctrl.loading = false;
				})

				var start = $ctrl.currentWeek.clone();

				$ctrl.week = [];
				var i = 0;
				var date = null;
				for (i = 0; i < 6; i++) {
					date = start.clone().add(i, "days");
					$ctrl.week.push({ iso: date.clone().format("YYYY-MMM-DD"), date: date, isToday: date.isSame(moment(), "day") });
				}
			}else{
				$ctrl.loading = true;
				Establishment.getLocationAvailabilities({
					id: $ctrl.establishment.id,
					filter: {
						include: ['location', 'doctor', 'appointment'],
						where: { start: { between: [$ctrl.currentWeek.clone().startOf("day"), $ctrl.currentWeek.clone().add(7, "days").startOf("day")] }, locationId: $ctrl.location }
					}
				}).$promise.then(function(result) {
					$ctrl.result = result;
					$ctrl.locationAvailabilities = _.groupBy(result, function(item) {
						item.start = moment(item.start).utc();
						item.end = moment(item.end).utc();
						return moment(item.start).utc().startOf('day').format("YYYY-MMM-DD");
					});
					$ctrl.loading = false;
				})

				var start = $ctrl.currentWeek.clone();

				$ctrl.week = [];
				var i = 0;
				var date = null;
				for (i = 0; i < 6; i++) {
					date = start.clone().add(i, "days");
					$ctrl.week.push({ iso: date.clone().format("YYYY-MMM-DD"), date: date, isToday: date.isSame(moment(), "day") });
				}

			} 

		}

		$ctrl.nextWeek = function() {
			$ctrl.currentWeek = $ctrl.week[5].date.add(1, "day");
			$ctrl.getWeek();
		}

		$ctrl.previousWeek = function() {
			$ctrl.currentWeek = $ctrl.week[0].date.clone().subtract(6, "days");
			$ctrl.getWeek();
		}
	}
})