angular.module('app').component('singleReport', {
	bindings: {
		establishment: '<'
	},
	templateUrl: 'app/components/single-report/single-report.html',
	controller: function(SingleReport, Establishment) {
		this.editing = false;

		this.addLine = function(fieldName) {
			this.data[fieldName].push({});
		}

		this.save = function() {
			this.loading = true;

			if (this.establishment.singleReports) {
				SingleReport.prototype$updateAttributes({ id: this.establishment.singleReports.id }, this.data).$promise.then(result => {
					console.log(result);
					this.editing = false;
					this.loading = false;
				}).catch(error => {
					console.log(error);
					this.editing = false;
					this.loading = false;
				});
			} else {
				Establishment.singleReports.create({ id: this.establishment.id }, this.data).$promise.then(result => {
					console.log(result);
					this.editing = false;
					this.loading = false;
				}).catch(error => {
					console.log(error);
					this.editing = false;
					this.loading = false;
				});
			}
		};

		this.getPadding = function(label) {
			let dots = 1;

			if (label) {
				let firstWord = label.split(" ")[0];

				dots = (firstWord.match(/./g) || []).length;
				dots = dots / 2;
			}

			return 'padding-left: ' + dots * 16 + 'px;';
		};

		this.getData = function() {
			this.loading = true;
			this.background = {};

			SingleReport.getData({ establishmentId: this.establishment.id }).$promise.then(result => {
				_.forEach(result, (value, key) => {
					if (result.hasOwnProperty(key) && key !== '$promise' && key !== '$resolved') {
						this.data[key] = value;
						this.background[key] = true;
					}
				});

				this.loading = false;
			}).catch(error => {
				console.log(error);
			});
		};

		this.$onInit = function() {
			this.singleReportSections = [];
			this.data = this.establishment.singleReports ? this.establishment.singleReports : {};
			this.loading = true;

			SingleReport.getMetadata().$promise.then(function(result) {
				_.forEach(result, function(value, key) {
					if (result.hasOwnProperty(key) && key !== '$promise' && key !== '$resolved') {
						let section = value;
						section.atributeName = key;

						if (key.substring(0, 2) === 'IV') {
							section.sectionName = 'IV';
						} else if (key.substring(0, 3) === 'III') {
							section.sectionName = 'III';
						} else if (key.substring(0, 2) === 'II') {
							section.sectionName = 'II';
						} else if (key.substring(0, 1) === 'I') {
							section.sectionName = 'I';
						} else if (key.substring(0, 1) === 'V') {
							section.sectionName = 'V';
						}

						section.multipleEntries = Array.isArray(value.type);

						if (section.multipleEntries && !this.data[section.atributeName]) {
							this.data[section.atributeName] = [{}];
						}

						this.singleReportSections.push(section);
					}
				}.bind(this));

				this.singleReportFields = _.groupBy(this.singleReportSections, 'sectionName');

				this.singleReportSections = this.singleReportSections.filter(section => section.title);
				this.singleReportSections.forEach(function(section) {
					if (section.sectionName === 'I') {
						section.collapsed = false;
					} else {
						section.collapsed = true;
					}

					section.fields = this.singleReportFields[section.sectionName];
				}.bind(this));

				this.loading = false;

			}.bind(this)).catch(error => {
				console.log(error);
			});
		};
	}
});
