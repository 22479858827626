angular.module('app').component('topnav', {
	bindings: {
		onToggle: '&',
	},
	templateUrl: 'app/components/topnav/topnav.html',
	controller: function(Auth) {
		var $ctrl = this;


		this.$onInit = function() {
			var user = Auth.getCachedCurrent();
			$ctrl.user = user;
		}

		$ctrl.toggleMenu = function() {
			$ctrl.onToggle();
		};

		$ctrl.openSupport = () =>{
			Tawk_API.toggle()
		}
	}
})