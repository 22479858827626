angular.module('app').component('scheduling', {
	bindings: { patient: '<' },
	templateUrl: 'app/components/scheduling/scheduling.html',
	controller: function(Doctor, Auth, Establishment, $stateParams, Patient, Location) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();
		$ctrl.user = user;
		$ctrl.start = $stateParams.start;

		this.$onInit = function() {
			$ctrl.status = [{ name: "cancelled", label: "Cancelada" }, { name: "started", label: "Em progresso" }, { name: "completed", label: "Realizado" }, { name: "confirmed", label: "Agendado" }, { name: "requested-by-doctor", label: "Por aceitar - pedido Médico" }, { name: "requested-by-patient", label: "Por aceitar - pedido Trabalhador" }, { name: "requested-by-operational", label: "Por aceitar pelo trabalhador" }, { name: "cancelled", label: "Cancelada" }, { name: "absent", label: "Falta" }]
			$ctrl.periocities=['Anual', 'Bianual']
			$ctrl.selectedPatients = [];
			$ctrl.allSelected = false;
			$ctrl.allSelectedSpecial = false;
			if ($ctrl.user.role === "operational" || $ctrl.user.role === "hst") {
				Establishment.managesEstablishments({ id: user.staff.establishment.id, filter: { include: ['company', 'doctors', {company:'establishments'}] } }).$promise.then(function(companies) {
					$ctrl.companies = companies;
				});
			} else if ($ctrl.user.role === "doctor" || $ctrl.user.role === "nurse") {
				$ctrl.companies = $ctrl.patient.establishment;
				$ctrl.selectEstablishment($ctrl.companies);
			} else if ($ctrl.user.role === "patient") {
				$ctrl.companies = user.patient.establishment;
				$ctrl.selectEstablishment($ctrl.companies)
			}
		}

		$ctrl.getStatus= function(status){
			const findStatus = _.find($ctrl.status,{name:status})
			if(findStatus) return findStatus.label
		
			return ''
		}

		$ctrl.getAllPatients = function() {
			Establishment.getPatientsAppointments({
				id: $ctrl.selectedEstablishment.id,
				options: { search: $ctrl.search, filterScheduled: $ctrl.filterScheduled, filterPeriocity: $ctrl.filterPeriocity }

			}).$promise.then(function(result) {
				$ctrl.patients = result;
			})
			
		}

		$ctrl.getPatientsAnual = function() {
			Establishment.getPatientsAppointmentsSpecial({
				id: $ctrl.selectedEstablishment.id,
				options: { search: $ctrl.searchSpecial, filterScheduled: $ctrl.filterScheduledSpecial }
			}).$promise.then(function(result) {
				result.forEach(element => {
					element.isSpecial = true;
				});

				$ctrl.patients = result;
			})
		}
		$ctrl.getPatients = function() {
			if ($ctrl.user.role === "doctor" || $ctrl.user.role === "nurse") {
				$ctrl.patients = [$ctrl.patient];
				$ctrl.addPatient($ctrl.patient);
				return;
			}
			if ($ctrl.user.role === "patient") {
				$ctrl.patients = [user.patient];
				Patient.prototype$__get__supervising({ id: user.patient.id }).$promise.then(function(supervisors) {
					$ctrl.patients = _.concat($ctrl.patients, supervisors)
				})
				return;
			}
			$ctrl.getAllPatients();
			//$ctrl.getPatientsBianual();
		}

		$ctrl.selectEstablishment = function(establishment, location) {
			$ctrl.searchFilter = '';
			$ctrl.selectedEstablishment = establishment;
			$ctrl.selectedLocation = location;
			//console.log($ctrl.selectedLocation);
			$ctrl.getPatients();
			//console.log(establishment);
		}



		$ctrl.addPatient = function(patient) {
			if (_.some($ctrl.selectedPatients, patient))
				_.remove($ctrl.selectedPatients, patient)
			else
				$ctrl.selectedPatients.push(patient);

			$ctrl.isChecked();

			$ctrl.selectedPatients = $ctrl.selectedPatients.slice(0);
		}

		$ctrl.isPatient = function(patient) {
			return _.some($ctrl.selectedPatients, patient);
		}

		$ctrl.isChecked = function() {
			if (!$ctrl.patients)
				return $ctrl.allSelected = false;
			return $ctrl.allSelected = ($ctrl.selectedPatients.length === $ctrl.patients.length);
		};

		$ctrl.isCheckedSpecial = function() {
			if (!$ctrl.patientsSpecial)
				return $ctrl.allSelected = false;
			return $ctrl.allSelected = ($ctrl.selectedPatients.length === $ctrl.patientsSpecial.length);
		};

		$ctrl.toggleAll = function() {
			if (!$ctrl.patients)
				return false;
			if (!$ctrl.allSelected) {
				$ctrl.selectedPatients = $ctrl.selectedPatients.filter((element) => element.isSpecial === true);
			} else if ($ctrl.allSelected) {
				$ctrl.selectedPatients = $ctrl.selectedPatients.concat($ctrl.patients.slice(0));
			}
		};

		$ctrl.toggleAllSpecial = function() {
			if (!$ctrl.patientsSpecial)
				return false;
			if (!$ctrl.allSelectedSpecial) {
				$ctrl.selectedPatients = $ctrl.selectedPatients.filter((element) => element.isSpecial === false);
			} else if ($ctrl.allSelectedSpecial) {
				$ctrl.selectedPatients = $ctrl.selectedPatients.concat($ctrl.patientsSpecial.slice(0));
			}
		};

		$ctrl.getAppointmentStatus = function(date, birthday) {

			//console.log(date, birthday);
			
			if (!date)
				return "danger";

			var days = moment(date).add($ctrl.getPeriocity(birthday)==='Anual' ? 1 : 2, "year").diff(moment(), "months");

			if (days <= 1)
				return "danger";

			else if (days <= 3)
				return "warning";

			return "normal";
		}

		$ctrl.getYears = function(date) {
			if (!date)
				return "s/ consultas"

			return moment(date).add(2, "year").diff(moment(), "months") + " meses";
		}

		$ctrl.getYearsSpecial = function(date) {
			if (!date)
				return "s/ consultas"
			return moment(date).add(1, "year").diff(moment(), "months") + " meses";

		}

		$ctrl.getPeriocity = function(date) {
			if (!date)
				return "sem data nascimento definida"

			return moment(date).isSameOrBefore(moment().subtract(50,'years')) ? 'Anual' : 'Bianual';
		}
	}
});