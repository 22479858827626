'use strict';

angular.module('app').component('chatMessageItem', {
	templateUrl: 'app/components/chat/chat-message-item.html',
	bindings: {
		user: '<',
		recipient: '<',
		message: '<'
	},
	controller: [
		function() {}
	]
});
