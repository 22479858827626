'use strict';

angular.module('app').component('chatNewConversationList', {
	templateUrl: 'app/components/chat/chat-new-conversation-list.html',
	bindings: {
		openChat: '&',
		isExternal: '='
	},
	controller: [
		'Auth',
		'ChatService',
		function(Auth, ChatService) {
			this.query = '';

			this.users = [];

			this.toggleFilter = function(name) {
				this.filter[name] = !this.filter[name];
				this.search();
			}

			this.search = function() {
				this.createFilter();

				var q = this.query;
				if (_.isEmpty(q)) {
					this.users.length = 0;
					return;
				}

				ChatService.searchUsers(q, this.filter)
					.then(angular.bind(this, function(result) {
						angular.copy(result, this.users);
					}));
			};

			this.click = function(user) {
				this.openChat({ user: user });
			};

			this.createFilter = function() {
				if (!this.filter) {
					this.filter = {
						worker: this.isExternal ? !this.isExternal : true,
						operational: true,
						hst: true,
						financial: this.isExternal ? !this.isExternal : true,
						doctor: this.isExternal ? !this.isExternal : true,
						nurse: this.isExternal ? !this.isExternal : true,
						external: this.isExternal ? !this.isExternal : true
					};
				}
			};
		}
	]
});
