'use strict';

angular.module('app').component('digitalSignature', {
	templateUrl: 'app/components/digital-signature/digital-signature.html',
	bindings: {
		title: '<',
		afterSign: '=',
		modalId: '<'
	},
	controller: function(Auth, LxDialogService) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();

		this.data = {};
		this.saving = false;

		this.cancel = function() {
			angular.copy({}, this.data);
			LxDialogService.close($ctrl.modalId);
		};

		this.checkSignature = function() {
			var sign = this.signature();
			if (sign.isEmpty || !sign.dataUrl) {
				return;
			}

			var img = sign.dataUrl.replace('data:image/png;base64,', '');

			LxDialogService.close($ctrl.modalId);

			this.clearSignature();
			this.save(img);
		};

		this.cancelSignature = function() {
			LxDialogService.close($ctrl.modalId);
		};

		this.save = function(signature) {
			this.saving = true;
			this.afterSign(signature);
		};
	}
});
