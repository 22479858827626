'use strict';

angular.module('app').component('chatConversationItem', {
	templateUrl: 'app/components/chat/chat-conversation-item.html',
	bindings: {
		conversation: '<'
	},
	controller: [
		function() {}
	]
});
