'use strict';

angular.module('app').factory('ExcelService', ['Company', 'moment', function(Company, moment) {
  function checkPatient(options, patient) {
    if(options.on) {
      if(options.occupation && !patient.occupationId) {
        return true; 
      }

      if(options.hireDate && !patient.hireDate) {
        return true;
      }
      
      if(options.birthday && !patient.birthday) {
        return true;
      }
      
      if(options.gender && !patient.gender) {
        return true;
      }

      if(options.dateTime && !patient.appointments.length) {
        return true;
      }

      if(options.nacionality && !patient.nacionality) {
        return true;
      }

      return false;
    } else {
      return true;
    }
  }


  function exportPatientsToExcel(companyId, options) {
    return new Promise(function(callback, errorCallback) {
      let companyName;
      let where = {};

      Company.findById({ id: companyId, filter: { 
          include: [{
            managesEstablishments: ['company', {
              patients: [{occupation: 'category'}, 'appointments', 'patientDeadlines']
            }]
          },
          { 
            establishments: ['company', {
              patients: [{occupation: 'category'}, 'appointments', 'patientDeadlines']
          }],
          }],
          where: where
        }}).$promise.then(function(company)  {
          let patients = [];
          companyName = company.name;

          company.managesEstablishments = [...company.managesEstablishments, ...company.establishments];

          company.managesEstablishments.forEach(function(establishment) {
            establishment.patients.forEach(function(patient) {
              
              if(checkPatient(options, patient)) {
                patients.push({
                  "Trabalhador":            patient && patient.name? patient.name: '',
                  "Nacionalidade":          patient && patient.nacionality? patient.nacionality : '',
                  "Género":                 patient && patient.gender? patient.gender === 'male'? 'Masculino': 'Feminino' : '',
                  "Data Nascimento":        patient && patient.birthday? moment(patient.birthday).format('DD/MM/YYYY') : '',
                  "Número Trabalhador":     patient && patient.workerNumber? patient.workerNumber : '',
                  "Categoria":              patient.occupation && patient.occupation.category ?patient.occupation.category.name: '',
                  "Função":                 patient.occupation? patient.occupation.name: '',
                  "Data Admissão":          patient && patient.hireDate? moment(patient.hireDate).format('DD/MM/YYYY') : '',
                  "Data Ultima Consulta":   patient.appointments && patient.appointments[0]? moment(patient.appointments[0].dateTime).format('DD/MM/YYYY'): '',
                  "Data Próxima Consulta":  patient.patientDeadlines? moment(patient.patientDeadlines.deadline).format('DD/MM/YYYY'): '',
                  "Empresa":                establishment && establishment.company && establishment.company.name? establishment.company.name : '',
                  "Estabelecimento":        establishment && establishment.name? establishment.name : ''
              }); 
              }
            });
          });

          patients = _.uniqBy(patients, 'Trabalhador');
    
        alasql('SELECT * INTO XLSX("Trabalhadores-'+companyName+'.xlsx",{headers:true}) FROM ?',[patients]);
        callback(patients);
      }).catch(function(error) {
        errorCallback(error);
      });
    });
  };

	return {
		exportPatientsToExcel: exportPatientsToExcel
	};
}]);
