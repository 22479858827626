'use strict';

angular.module('app').component('loader', {
	templateUrl: 'app/components/loader/loader.html',
	bindings: {
		loading: '<',
		text: '<'
	},
	controller: function() { }
});
