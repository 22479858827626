'use strict';

angular.module('app').component('chatMessageList', {
	templateUrl: 'app/components/chat/chat-message-list.html',
	bindings: {
		user: '<',
		conversation: '<'
	},
	controller: [
		'$scope',
		'$rootScope',
		'$timeout',
		'socket',
		'uuid',
		'ChatService',
		function($scope, $rootScope, $timeout, socket, uuid, ChatService) {
			var self = this;

			this.message = '';
			this.messages = [];

			this.loadMessages = function() {
				if (this.conversation.lastMessage) {
					this.messages.push(_.extend({}, this.conversation.lastMessage || {}, {}));
				}

				ChatService.getMessages(self.conversation.id)
					.then(function(result) {
						self.messages.length = [];
						angular.copy(result, self.messages);

						_.each(result, function(message) {
							if ((message.status === 'server' || message.status === 'delivered') && message.ownerId !== self.user.id) {
								ChatService.updateStatus(message.id, message.ownerId, 'read');
							}
						});
					});
			};

			this.send = function() {
				if (_.isEmpty(this.message)) {
					return;
				}

				ChatService.sendMessage(
					this.conversation.id,
					this.user.id,
					this.conversation.recipient.id,
					this.message
				);

				this.message = '';
			};

			this.checkRead = _.debounce(function() {
				_.each(self.messages, function(message) {
					if ((message.status === 'server' || message.status === 'delivered') && message.ownerId !== self.user.id) {
						ChatService.updateStatus(message.id, message.ownerId, 'read');
					}
				});
			}, 500);

			// When recipient changed, load the messages
			this.$onChanges = function(changes) {
				if (changes.conversation && changes.conversation.currentValue) {
					this.messages.length = [];
					this.loadMessages();
				}
			};

			$rootScope.$on('chat.message', function(evt, data) {
				if (self.conversation && data.conversationId === self.conversation.id) {
					var current = _.find(self.messages, { id: data.id });
					if (!current) {
						$timeout(function() {
							self.messages.push(data);
						});
					}
				}
			});

			$rootScope.$on('chat.status', function(evt, data) {
				if (self.conversation && data.conversationId === self.conversation.id) {
					var current = _.find(self.messages, { id: data.id });
					if (current) {
						$timeout(function() {
							current.status = data.status;
						});
					}
				}
			});
		}
	]
});
