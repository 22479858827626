'use strict';

angular.module('app').factory('StaticLists', function() {
	var genders = [{
		id: 'male',
		name: 'Masculino'
	}, {
		id: 'female',
		name: 'Feminino'
	}];

	var ages = [{
		id: '20-30',
		name: '20-30'
	}, {
		id: '31-40',
		name: '31-40'
	}, {
		id: '41-50',
		name: '41-50'
	}, {
		id: '51-60',
		name: '51-60'
	}, {
		id: '61-70',
		name: '61-70'
	}, {
		id: 'other',
		name: '>70'
	}];

	var households = [{
		id: '1',
		name: '1'
	}, {
		id: '2',
		name: '2'
	}, {
		id: '3',
		name: '3'
	}, {
		id: '4',
		name: '4'
	}, {
		id: '5',
		name: '5'
	}, {
		id: 'other',
		name: '>5'
	}];

	var educations = [{
		id: 'preschool',
		name: '1º Ciclo'
	}, {
		id: 'primary',
		name: '2º Ciclo'
	}, {
		id: 'secondary',
		name: 'Secundário'
	}, {
		id: 'graduate',
		name: 'Superior'
	}];

	var appointmentTypes = [{
		id: 'admission',
		name: 'Admissão'
	}, {
		id: 'occasional',
		name: 'Ocasional'
	}, {
		id: 'periodic',
		name: 'Periódico'
	}, {
		id: 'other',
		name: 'Outra'
	}];

	var appointmentStatus = [{
		id: 'completed',
		name: 'Realizados'
	}, {
		id: 'delayed',
		name: 'Em atraso'
	}, {
		id: 'scheduled',
		name: 'Por realizar'
	}];

	var bmiScales = [{
		id: 'imc0',
		name: 'Saudável'
	}, {
		id: 'imc1',
		name: 'Sobrepeso'
	}, {
		id: 'imc2',
		name: 'Obesidade Grau I'
	}, {
		id: 'imc3',
		name: 'Obesidade Grau II'
	}, {
		id: 'imc4',
		name: 'Obesidade Grau III'
	}];

	var fitnessResults = [{
		id: 'fit',
		name: 'Apto'
	}, {
		id: 'unfit',
		name: 'Inapto'
	}, {
		id: 'conditionally-fit',
		name: 'Apto condicionalmente'
	}, {
		id: 'temporarily-unfit',
		name: 'Inapto temporário'
	}];

	var perDiemsStatus = [{
		id: 'under-evaluation',
		name: 'Em avaliação'
	}, {
		id: 'disapproved',
		name: 'Reprovada'
	}, {
		id: 'approved',
		name: 'Aprovada'
	}];

	var months = [{
		id: '1',
		name: 'Janeiro'
	}, {
		id: '2',
		name: 'Fevereiro'
	}, {
		id: '3',
		name: 'Março'
	}, {
		id: '4',
		name: 'Abril'
	}, {
		id: '5',
		name: 'Maio'
	}, {
		id: '6',
		name: 'Junho'
	}, {
		id: '7',
		name: 'Julho'
	}, {
		id: '8',
		name: 'Agosto'
	}, {
		id: '9',
		name: 'Setembro'
	}, {
		id: '10',
		name: 'Outubro'
	}, {
		id: '11',
		name: 'Novembro'
	}, {
		id: '12',
		name: 'Dezembro'
	}];

	var specialty = [{
		id: '0',
		name: 'Anestesiologia'
	}, {
		id: '1',
		name: 'Cirurgia Vascular'
	}, {
		id: '2',
		name: 'Cardiologia'
	}, {
		id: '3',
		name: 'Cirurgia Geral'
	}, {
		id: '4',
		name: 'Cirurgia Maxilo-Facial'
	}, {
		id: '5',
		name: 'Cirurgia Plástica'
	}, {
		id: '6',
		name: 'Doenças Infecciosas'
	}, {
		id: '7',
		name: 'Endocrinologia'
	}, {
		id: '8',
		name: 'Estomatologia'
	}, {
		id: '9',
		name: 'Gastroenterologia'
	}, {
		id: '10',
		name: 'Imunoalergologia'
	}, {
		id: '11',
		name: 'Imunohemoterapia'
	}, {
		id: '12',
		name: 'Hematologia Clinica'
	}, {
		id: '13',
		name: 'Medicina Desportiva'
	}, {
		id: '14',
		name: 'Medicina Fisica e de Reabilitação'
	}, {
		id: '15',
		name: 'Medicina Geral e Familiar'
	}, {
		id: '16',
		name: 'Medicina Interna'
	}, {
		id: '17',
		name: 'Medicina Tropical'
	}, {
		id: '18',
		name: 'Nefrologia'
	}, {
		id: '19',
		name: 'Neurocirurgia'
	}, {
		id: '20',
		name: 'Oftalmologia'
	}, {
		id: '21',
		name: 'Oncologia Médica'
	}, {
		id: '22',
		name: 'Ortopedia'
	}, {
		id: '23',
		name: 'Otorrinolaringologia'
	}, {
		id: '24',
		name: 'Pneumologia'
	}, {
		id: '25',
		name: 'Radiologia'
	}, {
		id: '26',
		name: 'Psiquiatria'
	}, {
		id: '27',
		name: 'Reumatologia'
	}, {
		id: '28',
		name: 'Saúde Pública'
	}, {
		id: '29',
		name: 'Urologia'
	}];

	var healthActions = [{
		id: 'promotion',
		name: 'Promoção'
	}, {
		id: 'prevention',
		name: 'Prevenção'
	}, {
		id: 'awareness',
		name: 'Sensibilização'
	}];

	var contractSituationList = [{
		id: 'temp',
		name: 'Trabalhadores temporários cedidos por contrato de utilização'
	}, {
		id: 'freelancer',
		name: 'Trabalhadores independentes'
	}, {
		id: 'externalCompanyWorker',
		name: 'Trabalhadores ao serviço de empresas prestadoras de serviços'
	}, {
		id: 'ocasional',
		name: 'Trabalhadores em regime de cedência ocasional'
	}];

	var validationType = ['Conforme', 'Não conforme', 'Não aplicável'];
	var historyStatus = [
		{ id: '1', name: 'Aceite' },
		{ id: '2', name: 'Rejeitado' },
		{ id: '3', name: 'Em avaliação' }
	];
	function translate(value, list) {
		var found = _.find(list, { id: value });
		if (found) {
			return found.name;
		}

		return value;
	}

	function translateArray(arr, list) {
		return _.map(arr, function(item) {
			return translate(item, list);
		});
	}

	function modelToSelection(list, data, callback) {
		if (!data) {
			return callback();
		}

		callback(_.find(list, { id: data }));
	}

	function selectionToModel(data, property, callback) {
		if (!callback && typeof property === 'function') {
			callback = property;
			property = 'id';
		}

		callback(_.get(data, property));
	}

	return {
		genders: genders,
		ages: ages,
		households: households,
		educations: educations,
		appointmentTypes: appointmentTypes,
		bmiScales: bmiScales,
		fitnessResults: fitnessResults,
		perDiemsStatus: perDiemsStatus,
		months: months,
		healthActions: healthActions,
		specialty: specialty,
		validationType: validationType,
		historyStatus: historyStatus,
		contractSituationList: contractSituationList,
		appointmentStatus: appointmentStatus,

		translate: translate,
		translateArray: translateArray,
		modelToSelection: modelToSelection,
		selectionToModel: selectionToModel,

		toSelection: modelToSelection,
		toModel: selectionToModel
	};
});
