'use strict';

angular.module('app').component('chatConversationList', {
	templateUrl: 'app/components/chat/chat-conversation-list.html',
	bindings: {
		conversations: '<',
		onSelectionChanged: '&'
	},
	controller: [
		function() {
			this.select = function (conversation) {
				this.onSelectionChanged({ conversation: conversation });
			};
		}
	]
});
