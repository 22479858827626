angular.module('app').component('schedulingWeek', {
	bindings: { establishment: '<', patients: '<', getPatients: '&', start: '<' },
	templateUrl: 'app/components/scheduling-week/scheduling-week.html',
	controller: function(Doctor, Auth, Establishment) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();
		$ctrl.user = user;

		$ctrl.currentWeek = moment();
		$ctrl.$onChanges = function(changes) {
			if (changes.establishment && changes.establishment.currentValue)
				$ctrl.getWeek();
		}


		$ctrl.getWeek = function() {

			Establishment.getLocationAvailabilities({
				id: $ctrl.establishment.id,
				filter: { include: ['location', 'doctor', 'appointment'] }
			}).$promise.then(function(result) {
				$ctrl.locationAvailabilities = _.groupBy($ctrl.locationAvailabilities, function(item) {
					return moment(item.start).utc().startOf('day').format("YYYY-MMM-DD");
				});
			})

			var start = $ctrl.currentWeek.clone();

			$ctrl.week = [];
			var i = 0;
			var date = null;
			for (i = 0; i < 6; i++) {
				date = start.clone().add(i, "days");
				$ctrl.week.push({ iso: date.clone().format("YYYY-MMM-DD"), date: date, isToday: date.isSame(moment(), "day") });
			}
		}

		$ctrl.nextWeek = function() {
			$ctrl.currentWeek = $ctrl.week[5].date.add(1, "day");
			$ctrl.getWeek();
		}

		$ctrl.previousWeek = function() {
			$ctrl.currentWeek = $ctrl.week[0].date.clone().subtract(6, "days");
			$ctrl.getWeek();
		}
	}
})