angular.module('app').component('weekBlockAvailability', {
	bindings: {
		availabilities: '<',
		day: '<',
		patients: '<',
		establishment: '<',
		getWeek: '&',
		getPatients: '&'
	},
	templateUrl: 'app/components/week-block-availability/week-block-availability.html',
	controller: function(Doctor, Auth, moment, LxDropdownService, DoctorAvailability, LxDialogService, Establishment, Appointment, LxNotificationService, LocationAvailability) {
		var $ctrl = this;
		var user = Auth.getCachedCurrent();
		$ctrl.user = user;
		$ctrl.foundAppointments = [];


		$ctrl.exams = [{ name: "admission", label: "Admissão" }, { name: "periodic", label: "Periódico" }, { name: "disease", label: "Ocasional - Após doença" }, { name: "accident", label: "Ocasional - Após acidente" }, { name: "patientRequest", label: "Ocasional - A pedido do trabalhador" }, { name: "companyRequest", label: "Ocasional - A pedido do serviço" }, { name: "jobChange", label: "Ocasional - Por mudança de função" }, { name: "jobConditionsChanged", label: "Ocasional - Por alteração das condições de trabalho" }]
		if ($ctrl.user.role === "patient")
			$ctrl.exams = [{ name: "patientRequest", label: "Ocasional - A pedido do trabalhador" }];

		$ctrl.acceptProccesses = [{ name: "request", label: "Pedir confirmação de disponibilidade ao(s) trabalhador(es)" }, { name: "scheduled", label: "Confirmar de imediato todas as consultas" }]
		$ctrl.acceptProccess = $ctrl.acceptProccesses[1]

		$ctrl.selectedExam = $ctrl.exams[1];

		$ctrl.selectAppointment = function(appointment) {
			$ctrl.canSelect(appointment) ? appointment.selected = !appointment.selected : LxNotificationService.info("Para trocar uma previsão, primeiro selecione-a e depois selecione um periodo livre e de seguide carregue no botão 'Trocar'.");
		}

		$ctrl.$onChanges = function(changes) {
			if (changes.availabilities && changes.availabilities.currentValue) {
				$ctrl.availabilitiesPeriod = _.groupBy($ctrl.availabilities, "period");

				var availabilitiesByPeriod = $ctrl.availabilitiesPeriod;

				_.each($ctrl.availabilitiesPeriod, function(periods, keyPeriods, listPeriods) {



					/*
						times = _.each(times, function(time, keyTime, listTime) {
							listTime[keyTime] = _.groupBy(time, "locationId")
						})
					*/

					listPeriods[keyPeriods] = {
						possibleLocations: _.groupBy(listPeriods[keyPeriods], "locationId")
					}

					_.each(listPeriods[keyPeriods].possibleLocations, function(item) {
						item.times = _.countBy(item, { appointmentId: null })
					})
				})
				console.log($ctrl.availabilitiesPeriod);
			}
		}

		$ctrl.getAppointment = function(time) {
			if (!time)
				return;
			var count = 0;
			for (var i = 0; i < time.length; i++) {
				if (time[i].appointment) {
					count++;
				}
			}
			return count;
		}

		$ctrl.addAppointment = function(date, availabilities) {
			$ctrl.selectedDate = date;
			$ctrl.selectedAvailabilities = [availabilities];
			$ctrl.selectedAvailability = $ctrl.selectedAvailabilities[0];
			$ctrl.removeAvailabilities = [];
			$ctrl.selectedDoctors = [];
			$ctrl.selectedPeriods = [];

			//$ctrl.selectedDoctors = angular.copy($ctrl.establishment.doctors);
			$ctrl.selectedPatients = angular.copy($ctrl.patients);

			$ctrl.generateAppointments();

			LxDialogService.open($ctrl.day.iso);

		}

		$ctrl.generateAppointments = function() {
			$ctrl.loading = true;
			$ctrl.allAppointments = [];
			var patients = angular.copy($ctrl.selectedPatients)
			if (!patients.length) patients.push({ id: -1 })
			Establishment.generateAppointments({
				blocked: _.map($ctrl.removeAvailabilities, "id"),
				periods: _.map($ctrl.selectedPeriods, "id"), doctors: _.map($ctrl.selectedDoctors, "id"),
				id: $ctrl.establishment.id,
				locationAvailabilityId: $ctrl.selectedAvailability.id,
				patients: _.map(patients, "id")
			}).$promise.then(function(result) {
				$ctrl.allAppointments = result.all;
				$ctrl.loading = false;
			}).catch(function(err) {
				console.log("ERR", err);
				$ctrl.loading = false;
			})
		}

		$ctrl.ConfirmSaveAppointment = function () {
			var PatientIds = _.map($ctrl.selectedPatients, "id")
			if ($ctrl.selectedExam.name === "periodic"){
			Appointment.find({
				filter: {
					include: ["doctor" , "company" , "patient"],
					where:{
						and :[{
							patientId:{ inq: PatientIds}, 
							exam: "periodic", 
							status: "confirmed", 
							dateTime: {
							  between: [moment($ctrl.allAppointments[0].locationAvailability.start).subtract(6, "months").toISOString(), moment($ctrl.allAppointments[0].locationAvailability.start).add(6, "months").toISOString()]
							}
						}]
					}
				}
			}).$promise.then(function(result){
				 $ctrl.foundAppointments = result;
				if ($ctrl.foundAppointments.length > 0) {
					LxDialogService.open("ConfirmAppointment-" + $ctrl.day.iso);
				}
				else{
					$ctrl.saveAppointment();
				}})
			}
			else {
				$ctrl.saveAppointment();
			}
		}

		$ctrl.saveAppointment = function() {


			if ($ctrl.foundAppointments.length > 0){

				for(var i = 0; i < $ctrl.foundAppointments.length; i++){
					Appointment.destroyById({id : $ctrl.foundAppointments[i].id});

					LocationAvailability.prototype$updateAttributes({
			 			id: $ctrl.foundAppointments[i].locationAvailabilityId
					}, {
			  			appointmentId: null
					});
				}

			}

			var saveAppointments = _.filter($ctrl.allAppointments, function(item) {
				return item.patient !== null;
			})
			var appointments = [];
			var doctor = null;
			for (var i = 0; i < saveAppointments.length; i++) {
				if (saveAppointments[i].locationAvailability.doctor) {
					doctor = saveAppointments[i].locationAvailability.doctor.id;
				} else {
					doctor = null;
				}
				var status = "requested-by-operational";
				if ($ctrl.acceptProccess.name === "scheduled") {
					status = "confirmed"
				} else {
					if ($ctrl.user.role === "patient") {
						status = "requested-by-patient";
					} else if ($ctrl.user.role === "doctor" || $ctrl.user.role === "nurse") {
						status = "requested-by-doctor";
					}
				}


				appointments.push({
					status: status,
					duration: moment.duration(moment(saveAppointments[i].locationAvailability.end).diff(moment(saveAppointments[i].locationAvailability.start))).asMinutes(),
					dateTime: saveAppointments[i].locationAvailability.start,
					patientId: saveAppointments[i].patient.id,
					locationId: saveAppointments[i].locationAvailability.locationId,
					locationAvailabilityId: saveAppointments[i].locationAvailability.id,
					doctorId: doctor,
					exam: $ctrl.selectedExam.name || "periodic"
				})
			}

			Establishment.createAppointments({ id: $ctrl.establishment.id, appointments: appointments }).$promise.then(function(result) {
				$ctrl.getWeek();
				$ctrl.getPatients();
				angular.copy([], $ctrl.patients);
				LxDialogService.close($ctrl.day.iso)
			});
		}

		$ctrl.removePatients = function() {
			$ctrl.selectedPatients = _.map(_.filter($ctrl.allAppointments, function(item) { if (!item.selected && item.patient !== null) return true }), "patient");
			$ctrl.generateAppointments();
		}

		$ctrl.removeAvailability = function() {
			$ctrl.removeAvailabilities = _.concat($ctrl.removeAvailabilities,
				_.map(
					_.filter($ctrl.allAppointments, function(item) {
						if (item.selected && item.patient !== null) return true
					}),
					"locationAvailability")
			);
			$ctrl.generateAppointments();
		}

		$ctrl.isSelectedGenerated = function() {
			if (!$ctrl.allAppointments)
				return false;
			var one = false;
			for (var i = 0; i < $ctrl.allAppointments.length; i++) {
				if ($ctrl.allAppointments[i].selected) {
					one = true;
					if (!$ctrl.allAppointments[i].patient)
						return false;
				}
			}
			if (!one)
				return false;

			return true;
		}

		$ctrl.isSelectedFixed = function() {
			if (!$ctrl.allAppointments)
				return false;
			var one = false;
			for (var i = 0; i < $ctrl.allAppointments.length; i++) {
				if ($ctrl.allAppointments[i].selected) {
					one = true;
					if (!$ctrl.allAppointments[i].locationAvailability.appointment)
						return false;
				}
			}
			if (!one)
				return false;

			return true;
		}

		$ctrl.isSelectedOne = function() {
			if (!$ctrl.allAppointments)
				return false;

			var trade = 0;

			for (var i = 0; i < $ctrl.allAppointments.length; i++) {
				if ($ctrl.allAppointments[i].selected) {

					if ($ctrl.allAppointments[i].locationAvailability.appointment)
						return false;

					if ($ctrl.allAppointments[i].patient)
						trade++;

					if (!$ctrl.allAppointments[i].locationAvailability.appointment && !$ctrl.allAppointments[i].patient)
						trade++;
				}
				if (trade > 2)
					return false;
			}

			if (trade === 2)
				return true;
		}

		$ctrl.periods = {
			0: { name: "morning", label: "Manhã", start: { hour: 8, minute: 0 }, end: { hour: 12, minute: 0 }, min: { hour: 7, minute: 0 }, max: { hour: 13, minute: 0 } },
			1: { name: "afternoon", label: "Tarde", start: { hour: 13, minute: 0 }, end: { hour: 20, minute: 0 }, min: { hour: 13, minute: 0 }, max: { hour: 20, minute: 0 } },
			2: { name: "night", label: "Noite", start: { hour: 20, minute: 0 }, end: { hour: 24, minute: 0 }, min: { hour: 20, minute: 0 }, max: { hour: 24, minute: 0 } }
		};

		$ctrl.periodsArray = [
			{ id: 0, name: "morning", label: "Manhã", start: { hour: 8, minute: 0 }, end: { hour: 12, minute: 0 }, min: { hour: 7, minute: 0 }, max: { hour: 13, minute: 0 } },
			{ id: 1, name: "afternoon", label: "Tarde", start: { hour: 13, minute: 0 }, end: { hour: 20, minute: 0 }, min: { hour: 13, minute: 0 }, max: { hour: 20, minute: 0 } },
			{ id: 2, name: "night", label: "Noite", start: { hour: 20, minute: 0 }, end: { hour: 24, minute: 0 }, min: { hour: 20, minute: 0 }, max: { hour: 24, minute: 0 } }
		];

		$ctrl.getNumberOfAppointments = function() {
			return _.countBy($ctrl.allAppointments, function(item) {
				return item.patient !== null;
			}).true
		}

		$ctrl.cancelAppointment = function() {
			$ctrl.loading = true;
			var otherAvailabilities = _.filter($ctrl.allAppointments, function(item) {
				if (item.selected && item.locationAvailability.appointment)
					return true
			});
			Establishment.updateAppointments({
				id: $ctrl.establishment.id,
				appointments: _.map(otherAvailabilities, function(item) {
					return { id: item.locationAvailability.appointment.id, status: "cancelled" }
				})
			}).$promise.then(function() {
				_.each(otherAvailabilities, function(item) {
					item.locationAvailability.appointmentId = null;
					delete item.locationAvailability.appointment;
					item.selected = false;
				});
				$ctrl.getWeek();
				$ctrl.getPatients();
				$ctrl.loading = false;
			})
		}

		$ctrl.tradePatients = function() {

			var appointments = _.filter($ctrl.allAppointments, function(item) {
				if (item.selected)
					return true
			});

			var appointmentAux = angular.copy(appointments[0]);
			appointments[0].patient = appointments[1].patient;
			appointments[1].patient = appointmentAux.patient;

			appointments[0].selected = false;
			appointments[1].selected = false;

			//_.find($ctrl.generatedAppointments, { selected: true }).locationAvailability = availability;
		}

		$ctrl.canSelect = function(appointment) {
			if ($ctrl.user.role === "operational" || $ctrl.user.role === 'hst')
				return appointment.locationAvailability.appointment || appointment.patient || $ctrl.isSelectedGenerated() || appointment.selected;
			else
				return appointment.patient || appointment.selected || $ctrl.isSelectedGenerated();
		}
	}
})