angular.module('app').component('appointmentsCalendar', {
  bindings: {
    establishment: '<',
    patients: '<',
    getPatients: '&',
    start: '<',
    view: "=",
    location: '<'
  },
  templateUrl: 'app/components/appointments-calendar/appointments-calendar.html',
  controller: function (Doctor, Auth, Establishment, Location) {
    var $ctrl = this;
    var user = Auth.getCachedCurrent();
    $ctrl.user = user;

    $ctrl.currentMonth = moment().utc().startOf("month");

    $ctrl.$onChanges = function (changes) {
      if (changes.establishment && changes.establishment.currentValue)
        $ctrl.getMonth();
      console.log($ctrl.location);
      console.log($ctrl.establishment);
    }

    $ctrl.getMonth = function () {
      if ($ctrl.location == null) {
        $ctrl.loading = true;
        Establishment.getLocationAvailabilities({
          id: $ctrl.establishment.id,
          filter: {
            include: ['location', 'doctor', 'appointment'],
            where: {
              start: {
                between: [$ctrl.currentMonth.clone().startOf("day"), $ctrl.currentMonth.clone().add(1, "month")]
              }
            }
          }
        }).$promise.then(function (result) {
          $ctrl.result = result;
          $ctrl.locationAvailabilities = _.groupBy(result, function (item) {
            item.start = moment(item.start).utc();
            item.end = moment(item.end).utc();
            return moment(item.start).utc().startOf('day').format("YYYY-MMM-DD");
          });
          $ctrl.loading = false;
        })

        $ctrl.month = [];
        var i = 0;
        var j = 0;
        var days = [];
        var startWeek = null;
        var date = null;
        var start = $ctrl.currentMonth.clone().startOf("month").startOf("week");
        for (i = 0; i <= 5; i++) {
          if (i === 5 && date.get('month') !== $ctrl.currentMonth.get('month'))
            return
          days = [];
          startWeek = start.clone().add(i, "weeks");
          for (j = 0; j < 6; j++) {
            date = startWeek.clone().add(j, "days").utc();
            days.push({
              iso: date.clone().utc().format("YYYY-MMM-DD"),
              date: date.utc(),
              notThisMonth: date.get('month') !== $ctrl.currentMonth.get('month'),
              isToday: date.isSame(moment(), "day")
            });
          }
          $ctrl.month.push(days);
        }
      } else {
        $ctrl.loading = true;
        Establishment.getLocationAvailabilities({
          id: $ctrl.establishment.id,
          filter: {
            include: ['location', 'doctor', 'appointment'],
            where: {
              start: {
                between: [$ctrl.currentMonth.clone().startOf("day"), $ctrl.currentMonth.clone().add(1, "month")]
              },
              locationId: $ctrl.location
            }
          }
        }).$promise.then(function (result) {
          $ctrl.result = result;
          $ctrl.locationAvailabilities = _.groupBy(result, function (item) {
            item.start = moment(item.start).utc();
            item.end = moment(item.end).utc();
            return moment(item.start).utc().startOf('day').format("YYYY-MMM-DD");
          });
          $ctrl.loading = false;
        })

        $ctrl.month = [];
        var i = 0;
        var j = 0;
        var days = [];
        var startWeek = null;
        var date = null;
        var start = $ctrl.currentMonth.clone().startOf("month").startOf("week");
        for (i = 0; i <= 5; i++) {
          if (i === 5 && date.get('month') !== $ctrl.currentMonth.get('month'))
            return
          days = [];
          startWeek = start.clone().add(i, "weeks");
          for (j = 0; j < 6; j++) {
            date = startWeek.clone().add(j, "days").utc();
            days.push({
              iso: date.clone().utc().format("YYYY-MMM-DD"),
              date: date.utc(),
              notThisMonth: date.get('month') !== $ctrl.currentMonth.get('month'),
              isToday: date.isSame(moment(), "day")
            });
          }
          $ctrl.month.push(days);
        }

      }
    }

    $ctrl.nextMonth = function () {
      $ctrl.currentMonth.add(1, "months");
      $ctrl.getMonth();
    }

    $ctrl.previousMonth = function () {
      $ctrl.currentMonth.subtract(1, "months");
      $ctrl.getMonth();
    }
  }
})