'use strict';

angular.module('app').factory('ExternalEntitiesService', ['Establishment', 'Company', 'Manage', function(Establishment, Company, Manage) {
	function getExternalEntities(establishmentId) {
		return Company.getExternalCompanies({ establishmentId: establishmentId }).$promise;
	}

	function createManageByEntries(externalCompany, managedByEstablishmentsIds, establishmentId) {
		Promise.all(managedByEstablishmentsIds.map(function(item) {
			return Manage.create({ companyId: externalCompany.id, establishmentId: establishmentId, eManagedBy: item });
		})).then(function(links) {
			console.log(links);
		}).catch(function(error) {
			console.log(error);
		});
	}

	function createExternalEstablishment(externalCompany, managedByEstablishmentsIds, establishmentId) {
		if (!establishmentId) {
			return Company.establishments.create({ id: externalCompany.id }, { name: externalCompany.name, external: true }).$promise.then(function(resultEstablishment) {
				createManageByEntries(externalCompany, managedByEstablishmentsIds, resultEstablishment.id);
			}).catch(function(error) {
				console.log(error);
			});
		}
		//else {
		// 	return Company.establishments.destroyAll({ id: externalCompany.id }).$promise.then(function(destroyResult) {
		// 		return Company.establishments.create({ id: externalCompany.id }, { name: externalCompany.name }).$promise.then(function(resultEstablishment) {
		// 			createManageByEntries(externalCompany, managedByEstablishmentsIds, resultEstablishment.id);
		// 		}).catch(function(error) {
		// 			console.log(error);
		// 		});
		// 	}).catch(function(destroyError) {
		// 		console.log(destroyError);
		// 	});
		// }
	}

	return {
		getExternalEntities: getExternalEntities,
		createExternalEstablishment: createExternalEstablishment
	};
}]);
