'use strict';

angular.module('app').factory('FormHelper', [
	'moment',
	function (moment) {
		function Helper() {

		}

		Helper.prototype.masks = {
			date: {
				guide: true,
				mask: [/[0-3]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/],
				pipe: createAutoCorrectedDatePipe.default('dd-mm-yyyy')
			},
			nif: {
				mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/]
			},
			niss: {
				mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/]
			}
		};

		Helper.prototype.validField = function (errors) {
			return _.isEmpty(errors);
		};

		Helper.prototype.validBirthday = function (errors, value, minAge, maxAge) {
			if (!minAge) {
				minAge = 15;
			}

			if (!maxAge) {
				maxAge = 100;
			}

			if (!_.isEmpty(errors)) {
				return false;
			}

			var birthday = (value || '').trim();
			if (birthday.length === 0) {
				return true;
			}

			birthday = moment(birthday, 'DD-MM-YYYY', true);
			if (!birthday.isValid()) {
				return false;
			}

			var age = moment().diff(birthday, 'years');
			if (age <= minAge || maxAge > 100) {
				return false;
			}

			return true;
		};

		Helper.prototype.dateFromServer = function (date) {
			if (date && date.length > 0) {
				var result = moment(date);
				if (result.isValid()) {
					return result.format('DD-MM-YYYY');
				}
			}

			return null;
		};

		Helper.prototype.dateToServer = function (date) {
			if (date && date.length > 0) {
				var result = moment(date, 'DD-MM-YYYY', true);
				if (result.isValid()) {
					return result.format('YYYY-MM-DD');
				}
			}

			return null;
		};

		return new Helper();
	}
]);
