'use strict';

angular.module('app').factory('ChatService', [
	'$q',
	'$log',
	'socket',
	'uuid',
	'$rootScope',
	'ChatMessage',
	'ChatConversation',
	function($q, $log, socket, uuid, $rootScope, ChatMessage, ChatConversation) {
		var conversations = [];
		var messages = {};
		var pendingConversations = {};

		function getConversations() {
			return ChatConversation.getMyConversations().$promise
				.then(function(result) {
					result = _.map(result, function(x) {
						if (x.lastMessage) {
							x.lastMessage.time = moment.utc(x.lastMessage.time).toDate();
						}

						return x;
					});

					conversations = result;
					return conversations;
				});
		}

		function getMessages(id) {
			var query = {
				filter: {
					where: { conversationId: id },
					order: 'time ASC',
					limit: 100,
					skip: 0
				}
			};

			return ChatMessage.find(query).$promise
				.then(function(result) {
					result = _.map(result, function(x) {
						x.time = moment.utc(x.time).toDate();
						return x;
					});

					if (!messages[id]) {
						messages[id] = result;
					} else {
						angular.extend(messages[id], result);
					}

					return messages[id];
				})
				.catch(function(err) {
					$log.error('error on gettting the messages from user.', err);
				});
		}

		function sendMessage(conversationId, fromId, toId, message) {
			var time = moment.utc();
			var remoteData = {
				id: fromId + '-' + toId + '-' + uuid.v4(),
				ownerId: fromId,
				recipientId: toId,
				conversationId: conversationId,
				time: time.toISOString(),
				message: message,
				status: 'pending'
			};

			var localData = _.extend({}, remoteData, { time: time.toDate() });
			if (!messages[conversationId]) {
				messages[conversationId] = [localData];
			} else {
				messages[conversationId].push(localData);
			}

			socket.emit('chat-m', remoteData);
			$rootScope.$emit('chat.message', localData);

			if (pendingConversations[conversationId]) {
				delete pendingConversations[conversationId];
				$rootScope.$emit('chat.reload');
			}
		}

		function updateStatus(id, ownerId, status) {
			socket.emit('chat-s', {
				id: id,
				ownerId: ownerId,
				status: status
			});
		}

		function searchUsers(q, filters) {
			_.forEach(filters, (value, key) => {
				if (!value) {
					delete filters[key];
				}
			});

			return ChatConversation.searchUsers({ q: q, filters: _.keys(filters) }).$promise;
		}

		function getConversation(fromId, toId, toName) {
			var found = _.find(conversations, function(x) {
				return x.recipient && x.recipient.id === toId;
			});

			if (found) {
				return $q.when(found);
			}

			var params = { fromId: fromId, toId: toId };
			return ChatConversation.upsertWithWhere({ where: params }, params).$promise
				.then(function(result) {
					var conversation = {
						id: result.id,
						lastMessage: {},
						recipient: {
							id: toId,
							name: toName
						}
					};

					pendingConversations[conversation.id] = conversation;
					return conversation;
				});
		}

		// When a message was received, add it to the list
		socket.on('chat-m', function(data) {
			var message = _.extend({}, data, { time: moment.unix(data.time).toDate() });
			$rootScope.$emit('chat.message', message);
		});

		// When the status message was received, update the message
		socket.on('chat-s', function(data) {
			$rootScope.$emit('chat.status', data);
		});

		return {
			getConversations: getConversations,
			getMessages: getMessages,
			sendMessage: sendMessage,
			searchUsers: searchUsers,
			getConversation: getConversation,
			updateStatus: updateStatus
		};
	}
]);
