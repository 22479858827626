'use strict';

angular.module('app').component('fitCerts', {
	templateUrl: 'app/components/fit-certs/fit-certs.html',
	bindings: {
		company: '<',
		fitCerts: '<',
		year: '<',
		lastApppointments: '<'
	},
	controller: function(moment, StaticLists, $state, $scope) {
		this.activeTab = 0;
		this.activeTabTable = 0;
		this.tableType = 'periodic';
		this.staticLists = StaticLists;

		this.options = {
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true
					}
				}]
			}
		};

		this.reload = function(selectedYear) {
			$state.go('app.certs', { year: selectedYear }, { reload: 'app.certs' });
		};

		this.print = function() {

			Array.prototype.forEach.call(document.getElementsByClassName("hidePdf"), function(elem, index) {
				elem.style.display = "none";
			});

			document.getElementById("toPrint").hidden = false;

			document.getElementById("app").style.display = "none";

			document.getElementById("toPrintPrepend").appendChild(document.getElementById("pdf"));

			window.print();

			document.getElementById("toPrintAppend").appendChild(document.getElementById("pdf"));
			document.getElementById("toPrint").hidden = true;

			document.getElementById("app").style.display = "flex";

			Array.prototype.forEach.call(document.getElementsByClassName("hidePdf"), function(elem, index) {
				elem.style.display = "";
			});

		};

		$scope.$on('goBack', function() {
			this.customTable = [];
		}.bind(this));

		this.getChartData = function(tableType, establishments) {
			this.fitCerts = angular.copy(this.allFitCerts);
			let establishmentsIds = establishments.map(establishment => establishment.id);
			this.tableType = tableType;
			this.series = [];
			this.title = StaticLists.appointmentTypes.filter(appointmentType => appointmentType.id === tableType)[0].name;

			let index = 0;

			this.examsSubTypes = [
				{ id: "admission", name: "Admissão" },
				{ id: "periodic", name: "Periódico" },
				{ id: "disease", name: "Após doença" },
				{ id: "accident", name: "Após acidente" },
				{ id: "patientRequest", name: "A pedido do trabalhador" },
				{ id: "companyRequest", name: "A pedido do serviço" },
				{ id: "jobChange", name: "Por mudança de função" },
				{ id: "jobConditionsChanged", name: "Por alteração das condições de trabalho" }];

			_.forEach(this.fitCerts, function(value, key) {
				if (key === tableType) {
					let subTypes = [];

					if (tableType === 'periodic') {
						subTypes = ['completed', 'delayed', 'scheduled'];
						this.data = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
					} else if (tableType === 'occasional') {
						subTypes = ['completed', 'disease', 'accident'];
						this.data = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

					} else if (tableType === 'admission') {
						subTypes = ['completed'];
						this.data = [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];

					}

					_.forEach(subTypes, function(type) {
						this.fitCerts[key][type] = this.fitCerts[key][type].filter(appointment => establishmentsIds.indexOf(appointment.establishment.id) >= 0);

						let appointmentTypeDesc = _.find(StaticLists.appointmentStatus, function(appointmentType) { return appointmentType.id === type });
						if (!appointmentTypeDesc) {
							appointmentTypeDesc = _.find(this.examsSubTypes, function(appointmentType) { return appointmentType.id === type });
						}

						this.series.push(appointmentTypeDesc.name);

						_.forEach(_.groupBy(value[type], 'monthNumber'), function(valueMonth, keyMonth) {
							valueMonth = valueMonth.filter(appointment => establishmentsIds.indexOf(appointment.establishment.id) >= 0);

							this.data[index][parseInt(keyMonth)] = this.data[index][parseInt(keyMonth)] + valueMonth.length;
						}.bind(this));

						index = index + 1;
					}.bind(this));

				}
			}.bind(this));
		};

		this.buildCustomTable = function(tableType, type) {

			this.subtitle = _.find(StaticLists.appointmentStatus, function(appointmentType) { return appointmentType.id === type });
			if (!this.subtitle) {
				this.subtitle = _.find(this.examsSubTypes, function(appointmentType) { return appointmentType.id === type });
			}
			this.tabletitle = StaticLists.appointmentTypes.filter(appointmentType => appointmentType.id === tableType)[0].name;
			this.subtitle = this.subtitle.name;

			this.fitCerts[tableType][type].forEach(result => {
				result.age = moment().diff(moment(result.patient.birthday), 'years');
				if (result.dateTime) {
					result.examDate = moment(result.dateTime).format("DD-MM-YYYY");
				}

				if (result.age <= 50) {
					result.frequency = 'bianual';
					result.deadline = moment(result.dateTime).add(2, 'Y').format("DD-MM-YYYY");
				} else {
					result.frequency = 'anual';
					result.deadline = moment(result.dateTime).add(1, 'Y').format("DD-MM-YYYY");
				}
			});

			this.customTable = this.fitCerts[tableType][type].map(function(appointment) {
				if(!appointment.examDate && appointment.appointments) {
					const lastAppointment = appointment.appointments[appointment.appointments.length - 1];

					if(lastAppointment) {
						appointment.examDate = moment(lastAppointment.dateTime).format("DD-MM-YYYY");
						appointment.fitCertDate = appointment.examDate;
					}
				}

				return {
					examDate: appointment.examDate,
					examDesc: appointment.fitCerts && appointment.fitCerts.length ? StaticLists.fitnessResults.find(result => result.id === appointment.fitCerts[0].result).name : '',
					doctorId: appointment.doctorId,
					doctorName: appointment.doctor ? appointment.doctor.name : '',
					deadline: appointment.deadline,
					patientName: appointment.patient ? appointment.patient.name : '',
					establishmentName: appointment.establishment ? appointment.establishment.name : '',
					filename: appointment.fitCerts && appointment.fitCerts.length ? appointment.fitCerts[0].filename : '',
					frequency: appointment.frequency,
					occupationName: appointment.patient.occupation ? appointment.patient.occupation.name : '',
					workstationName: appointment.patient.workstations && appointment.patient.workstations.length ? appointment.patient.workstations[0].name : '',
					nextAppointmentId: appointment.nextAppointmentId,
					fitCertDate: appointment.fitCertDate
				}
			});
		};

		this.$onInit = function() {
			this.allFitCerts = angular.copy(this.fitCerts);
			this.selectedEstablishments = angular.copy(this.company.establishments);
			this.year = parseInt(this.year);
			this.selectedYear = this.year ? this.year : moment().year();
			this.labels = StaticLists.months.map(month => month.name.substring(0, 3));
			this.getChartData(this.tableType, this.selectedEstablishments);

			this.lastApppointments.forEach(appointment => {
				if (appointment.result) {
					appointment.examDesc = StaticLists.fitnessResults.find(result => result.id === appointment.result).name;
				} else {
					appointment.examDesc = '';
				}
			});

			this.fitTable = this.lastApppointments.filter(appointment => appointment.result === "fit");
			this.conditionallyFitTable = this.lastApppointments.filter(appointment => appointment.result === "conditionally-fit");
			this.temporalyFitTable = this.lastApppointments.filter(appointment => appointment.result === "temporarily-unfit");
			this.unfitTable = this.lastApppointments.filter(appointment => appointment.result === "unfit");
			this.withoutFitCertTable = this.lastApppointments.filter(appointment => !appointment.result);
		};
	}
});
