'use strict';

angular.module('app').factory('StaffService', [
	'Staff',
	function(Staff) {
		var limit = 20;
		var staff = [];

		function where(options, query, role) {
			query = ((query || '') + '').trim();
			if (query.length > 0) {
				var like = { like: '%' + query + '%' };

				options.filter.where = {
					or: [
						{ name: like }
					]
				};
			}

			if (role) {
				options.filter.where = {
					or: [
						{ role: role }
					]
				};
			}
		}

		// Count the number of staff
		function count(query) {
			var options = {
				filter: {}
			};

			where(options, query);
			return Staff.count(options.filter).$promise
				.then(function(result) {
					return result.count;
				});
		}

		// Search for a staff
		function search(query, page, role) {
			if (page < 1 || !_.isNumber(page)) {
				page = 1;
			}

			var options = {
				filter: {
					limit: limit,
					skip: limit * (page - 1)
				}
			};

			where(options, query, role);
			return Staff.find(options).$promise
				.then(function(items) {
					angular.copy(items, staff);
					return staff;
				});
		}

		// Get a single doctor
		function get(id) {
			var options = {
				id: id,
				filter: {
					include: "auth"
				}
			};

			return Staff.findById(options).$promise;
		}

		return {
			count: count,
			search: search,
			get: get,
			limit: function() {
				return limit;
			}
		};
	}
]);
